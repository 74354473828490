@font-face {
  font-family: "proxima_novabold";
  src: url("./assets/fonts/proxima_nova_bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/proxima_nova_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_novasemibold";
  src: url("./assets/fonts/proxima_nova_semibold-webfont.woff2") format("woff2"),
    url("./assets/fonts/proxima_nova_semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_novathin";
  src: url("./assets/fonts/proxima_nova_thin-webfont.woff2") format("woff2"),
    url("./assets/fonts/proxima_nova_thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_novaregular";
  src: url("./assets/fonts/proximanova-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/proximanova-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: auto;
}

/* ::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
  }
  
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #eff8fd;
    border: 2px solid #70acdb;
  }
  */

.container {
  max-width: 1320px;
  width: 100%;
  margin: auto;
  padding: 0 20px;
  background: transparent;
}

.w-auto {
  width: auto !important;
}

ul,
li {
  list-style: none;
}

.ck-editor__main ul,
li {
  list-style: unset;
}

.ck-editor__main ol {
  list-style-type: decimal !important;
  list-style-position: inside !important;
}

.ck-editor__main ul {
  list-style-type: disc !important;
  list-style-position: inside !important;
}

img {
  object-fit: cover;
}

/*Typography*/

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h4-small {
  font-family: "proxima_novabold";
  margin: 0;
  color: var(--h-color);
  line-height: 130%;
}

h1,
.h1 {
  font-size: var(--h1-size);
}

h2,
.h2 {
  font-size: var(--h2-size);
}

h3,
.h3 {
  font-size: var(--h3-size);
  font-family: "proxima_novabold";
}

h4,
.h4 {
  font-size: var(--h4-size);
}

h5,
.h5 {
  font-size: var(--h5-size);
  color: var(--black-color);
}

p,
.p {
  font-family: "proxima_novaregular";
  font-size: var(--p-font-size);
  line-height: 19px;
  margin-bottom: 0rem;
  color: var(--text-color);
}

.f-size-20 {
  font-size: var(--f-size-20) !important;
  font-family: "proxima_novabold";
}

.f-size-16 {
  font-size: var(--f-size-16) !important;
  font-family: "proxima_novasemibold";
}

.pb-12px {
  padding-bottom: 12px !important;
}

.f-size-14 {
  font-size: var(--f-size-14) !important;
  font-family: "proxima_novaregular";
}

span {
  display: inline-block;
}

a {
  font-size: var(--f-size-16);
  display: inline-block;
  text-decoration: none;
  font-family: "proxima_novaregular";
  color: var(--dashboard-blue);
  cursor: pointer;
}

a:hover {
  text-decoration: none;
  transition-duration: 0.4s;
}

.f-weight-500 {
  font-weight: var(--f-weight-500);
}

.small,
small {
  font-size: 70%;
  font-family: "proxima_novaregular";
}

.ms-0 {
  margin-left: 0 !important;
}

/* Root Variables  */

:root {
  /* Typography */
  --f-size-16: 16px;
  --p-font-size: 16px;
  --f-size-14: 14px;
  --f-size-20: 20px;
  --h1-size: 48px;
  --h2-size: 32px;
  --h3-size: 24px;
  /* colors  */
  --main-color: #59c5fe;
  --main-bg-color: #f4f4f4;
  --light-blue: #7299c7;
  --dashboard-blue: #15a07d;
  --main-dark-color: #15a07d;
  --text-color: #3d4244;
  --light-text-color: #929495;
  --ex-light-gray: #d0ece5;
  --icon-bg: #e8f6f2;
}

.download-pdf-btn {
  background: #e5322d !important;
  color: #fff !important;
  border-color: #e5322d !important;
}

.position-relative {
  position: relative;
}

.d-inline-block {
  display: inline-block !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column-none {
  flex-direction: unset !important;
}

.mt-20 {
  margin-top: 20px;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.align-items-end {
  align-items: end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.btn-border {
  border: 1px solid var(--main-dark-color) !important;
}

.text-main-dark {
  color: var(--main-dark-color) !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.d-none {
  display: none !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: end !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.text-center {
  text-align: center !important;
}

.block {
  display: block !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.bg-none {
  background-color: transparent !important;
}

.w-100 {
  width: 100% !important;
}

.m-auto {
  margin: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.d-block {
  display: block !important;
}

.mt-2 {
  margin-top: 10px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.calenderdata-popup {
  z-index: 9999;
}

.calender-view-button {
  border: none;
  background-color: #fff !important;
  overflow: hidden;
}

.popover-team-img {
  flex-wrap: wrap;
}

#calender .calender-client-cell p {
  white-space: break-spaces !important;
  align-items: flex-start !important;
  text-overflow: ellipsis !important;
  /* width: 86px !important; */
  width: 98px;
  overflow: hidden !important;
  text-wrap: nowrap !important;
  display: flex !important;
}

/* ====================
  Stepper css start
  ==================== */

#stepper .stepper {
  text-align: right;
  background: transparent;
}

#stepper .step-lable {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-direction: row-reverse;
}

#stepper .step-box {
  width: 100%;
}

#stepper .step-lable span {
  margin-top: 0;
  text-align: right;
}

#stepper .step-lable span svg.MuiStepIcon-root {
  fill: #e5e5e5;
}

#stepper .step-lable span svg.MuiStepIcon-active {
  fill: var(--main-color);
}

#stepper .step-lable span svg.MuiStepIcon-completed {
  fill: var(--main-color);
}

#stepper .step-lable span:nth-child(1) {
  margin: 0 0px 40px 0;
}

#stepper .MuiStepConnector-lineVertical {
  min-height: 57px;
}

/* #stepper .MuiStepConnector-alternativeLabel {
    top: -49px;
    right: -8px;
    left: auto;
} */

.stepper-box-main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

#stepper .form-box {
  width: calc(100% - 277px);
  padding-bottom: 40px;
}

.thankyou-page {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}

.border-none {
  border: none !important;
}

.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 4;
}

.thankyou-main-box::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #f4f4f4;
  z-index: 3;
}

.step-lable>span>div {
  position: relative;
}

.step-lable svg {
  position: relative;
  z-index: 2;
  fill: #c2c2c2;
  margin-left: 0;
  top: 50%;
  left: 33px;
  transform: translate(-50%, -50%);
}

.step-lable>span>div::after {
  position: absolute;
  content: "";
  background-color: #e5e5e5;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  top: -8px;
  left: 10px;
  z-index: 1;
}

/* .horizontal-stepper .step-lable>span>div::after {
    width: 64px;
    height: 64px;
    top: -8px;
    left: -10px;
    z-index: 1;
}

.horizontal-stepper .step-lable svg {
    top: 50%;
    left: 21px;
    transform: translate(-50% , -20%);
}

.horizontal-stepper .MuiStepConnector-alternativeLabel {
    top: -49px !important;
    right: 10px !important;
    left: auto !important;
} */

#stepper .step-lable .MuiStepLabel-active {
  color: #15a07d;
}

#stepper .step-lable .MuiStepLabel-completed {
  color: #929495;
}

.MuiStep-completed .step-lable svg path {
  fill: #fff;
}

.p-0 {
  padding: 0 !important;
}

.pr-10 {
  padding-right: 30px;
}

.MuiStep-completed .step-lable>span>div::after {
  background-color: #e5e5e5;
}

#stepper .content span {
  display: inline;
  color: #929495 !important;
}

.step-lable .MuiStepLabel-active svg {
  fill: #fff;
}

.step-lable .MuiStepLabel-active>div::after {
  background-color: #15a07d;
}

#stepper .step-lable .MuiStepLabel-completed {
  color: #000;
}

.MuiStep-completed .step-lable>span>div::after {
  background-color: #15a07d;
}

.MuiStep-completed .step-lable svg {
  fill: #fff;
}

.MuiStep-completed .MuiStepConnector-line {
  border: 2px solid;
  border-color: #15a07d;
}

/* ====================
  Stepper css end
  ==================== */

/* Account Setup css  */

.account-setup-top-content {
  padding-top: 56px;
  padding-left: 70px;
}

.account-setup-top-content h3 {
  color: var(--text-color);
  padding-bottom: 16px;
}

.account-setup-top-content p {
  color: var(--text-color);
  padding-bottom: 36px;
}

#account-setup {
  background: var(--main-bg-color);
}

.modal-submit-btn {
  margin-left: 20px;
}

.modal-submit-btn:hover {
  background-color: var(--main-dark-color);
  color: #fff;
  border-radius: 10px;
}

/* 
  ===================================
  Form Css Start  test
  ===================================
  */

#personal-info .personal-info {
  padding: 30px 40px 11px 40px;
}

#personal-info .personal-info+.personal-info {
  padding-top: 0;
}

.profile-info-heading {
  padding-left: 40px;
}

#personal-info .personal-info-main {
  background: #fff;
  margin-bottom: 40px;
}

.step-count {
  padding-bottom: 8px;
  font-size: 13px;
  color: #202e2a;
}

.employee-id {
  font-size: 16px;
  padding-bottom: 8px;
  color: #202e2a;
}

.employee-id span {
  font-family: "proxima_novabold";
}

#personal-info .form-input {
  width: 100%;
  transition: all 0.3s ease-in-out;
  margin-top: 8px;
}

#personal-info .field-margin {
  margin-top: 4px;
}

#personal-info label {
  text-transform: capitalize;
  margin-right: 0;
}

.form-field+.form-field {
  margin-top: 20px;
}

.validation-text {
  font-size: 12px;
  font-family: "proxima_novasemibold";
}

.danger {
  color: #ff5251;
}

.important {
  font-size: 20px;
}

input[type="date"] {
  background: url(../src/assets/images/calendar.png);
  background-position: 93%;
  background-repeat: no-repeat;
  background-size: 20px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

#personal-info .form-input-icon-bg {
  position: relative;
}

#personal-info .form-input-icon-bg::before {
  position: absolute;
  content: "";
  background: var(--icon-bg);
  width: 50px;
  height: 40px;
}

#personal-info .personal-info .date-input-filed input {
  text-align: left;
  margin-left: 0px;
}

#personal-info .form-input:hover div {
  background: rgba(218, 229, 235, 0.4);
  border-color: var(--main-color);
}

#personal-info .form-input:focus {
  border-color: var(--main-color);
  outline: var(--main-color);
}

.form-label::after {
  content: "*";
  margin-right: 4px;
  color: red;
}

#personal-info .form-heading {
  font-size: 20px;
  color: var(--text-color);
  font-family: "proxima_novasemibold";
}

#personal-info .form-heading-section {
  padding: 10px 0 !important;
  /* border-bottom: 1px solid #d9d9d9; */
}

#personal-info .form-checkbox label span {
  font-size: 16px;
  color: var(--text-color);
}

.form-checkbox.MuiCheckbox-root {
  color: #15a07d !important;
}

.checked-checkbox-stepper.Mui-checked {
  color: #15a07d !important;
}

.unchecked-checkbox-stepper.MuiCheckbox-root {
  /* color: #202E2A !important;  */
}

label {
  font-size: 16px;
}

.date-lable {
  font-size: 13px;
  color: #3d4244;
}

.date-input-filed svg {
  display: none;
}

.date-input-filed button {
  background: url(../src/assets/images/calendar.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  width: 14px;
  height: 14px;
  border-radius: 0;
}

.date-input-per button {
  background-size: 17px;
  margin-left: -6px;
}

.date-input-filed .MuiInputAdornment-positionEnd {
  width: 14px;
}

.file-label {
  width: 70px;
  height: 25px;
  background: #f5f5f5;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  color: #929495;
  cursor: pointer;
  line-height: 24px;
}

#personal-info .d-none {
  display: none;
}

.input-box {
  border: 1px solid #d0ece5;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding-left: 12px;
}

/* .form-box{ 
    font-size: 16px;
    background: var(--main-dark-color);
    text-transform: capitalize;
  } */

#back-btn {
  color: var(--text-color);
  font-size: 16px;
}

#next-btn {
  /* height: 56px; */
  height: 43px;
  background-color: var(--main-dark-color);
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  padding: 0 24px;
  box-shadow: none;
  text-transform: capitalize;
}

.main-css .next-btn {
  height: 56px;
  background-color: var(--main-dark-color);
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  padding: 0 24px;
  box-shadow: none;
}

.input-icon svg path {
  color: var(--main-dark-color);
}

.input-icon svg {
  margin-right: 10px;
}

#account-setup-form .Mui-disabled {
  display: none;
}

/* 
  =====================
  Form Table Css start 
  =====================
  */

.table-main {
  /* max-width: 1140px; */
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  padding: 28px 48px 0 48px;
}

#table .table-divider {
  width: 100%;
  height: 30px;
  background-color: #f5f5f5;
}

#table .table-row-name {
  width: 381px;
  padding: 0 16px;
}

#table .table-upload-text span {
  font-size: 14px;
  flex-direction: inherit;
  background: #f5f5f5;
  border-radius: 4px;
  width: 70px;
  height: 25px;
  text-align: center;
  font-size: 14px;
  color: #929495;
}

#table .table-upload-text svg {
  width: 14px;
}

/* #table .table-check svg {
    color: var(--main-color);
} */

.table-check1.MuiCheckbox-root {
  color: #15a07d !important;
}

.checked-checkbox.Mui-checked {
  color: #15a07d !important;
}

.unchecked-checkbox.MuiCheckbox-root {
  color: #202e2a !important;
}

/* .table-check-check svg.MuiCheckbox-root{
    color: #202E2A !important;
}
.table-check-check svg.Mui-checked{
    color: #15A07D !important;
} */

#table .border-bottom-none {
  border-bottom: none;
}

#table .table-body {
  border-bottom: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
}

#table .table-body td {
  padding: 0 16px;
}

.table-bottom-content {
  padding: 20px 0 40px 0;
}

/* Form Table scroll css */

.table-scroll {
  max-height: 730px;
  overflow: hidden;
}

/* Form Table scroll css */

/* 
  =====================
  Form Table Css end 
  =====================
  */

/* 
  @media (max-width: 1235px) {
    .pt-40{ 
        padding-top: 40px !important;
    }
    
  }
  
  @media (max-width: 768px) {
    #personal-info .personal-info {
        padding: 30px 20px;
    }
  
    #personal-info .form-heading-section {
        padding: 20px 20px;
    }
  
    #personal-info .input{ 
        margin-top: 0 !important;
    }
  
   
    
  } */

/* 
  ===================================
  Form Css End 
  ===================================
  */

/* 
  =======================
  Modal Css Start  
  =======================
  */

.modal-add-btn {
  padding: 36px 0 20px 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: "proxima_novasemibold";
}

.modal-add-btn button {
  background-color: transparent;
  cursor: default;
}

.modal-add-btn button:hover {
  background-color: transparent;
}

.modal-add-btn button:focus {
  background-color: transparent;
}

.modal-add-btn svg {
  margin-left: 16px;
  cursor: pointer;
  font-size: 24px;
  /* width: 16px; */
}

.modal-add-btn svg path {
  fill: var(--main-dark-color);
}

#modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-divider {
  background-color: #f5f5f5;
}

/* account setup footer  */

.account-setup-footer {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 0;
}

.account-setup-footer p {
  font-size: 12px;
  color: #000000;
  margin: 0 10px;
}

.dashboard-form .agency-form-box-btn-custom a {
  font-family: "general_sanssemibold";
}

/* 
  =======================
  Modal Css end  
  =======================
  */

.rc-time-picker-panel {
  z-index: 9999 !important;
}

/* 
  ==================
  Slider Css Start  
  ==================
  */

.carousel-container .carousel-bg {
  background: url(./assets/images/Banner.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel-container .carousel-container {
  width: 100%;
  height: calc(100vh - 100px);
}

.carousel-container .owl-theme {
  width: 100%;
  height: calc(100vh - 100px);
  margin: 0;
  padding: 0;
}

.carousel-container .owl-item {
  position: relative;
}

.carousel-container .owl-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.3;
  width: 100%;
  height: 100%;
}

.carousel-container .carousel-image-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.carousel-container .carousel-text {
  color: #fafafa;
  font-size: 46px;
  text-align: center;
  line-height: 46px;
  /* margin-top: 20px; */
}

.carousel-container .logo {
  width: 124px;
}

.login_logo {
  max-width: 120px;
  margin: auto;
}

.carousel-caption {
  position: absolute;
  /* top: 50%; */
  top: 75%;
  left: 0;
  right: 0;
  z-index: 5;
  transform: translate(0, -50%);
}

.login_logo img {
  max-width: 120px;
}

.owl-theme .owl-nav {
  display: none;
}

.owl-theme .owl-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.owl-carousel .owl-item img.banner-img {
  display: block;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.carousel-container .owl-theme .owl-dots .owl-dot.active span,
.carousel-container .owl-theme .owl-dots .owl-dot:hover span {
  width: 16px;
  height: 3px;
}

.carousel-container .owl-theme .owl-dots .owl-dot span {
  width: 4px;
  height: 4px;
  margin: 5px 4px;
}

/* 
  ==================
  Slider Css End  
  ==================
  */

/* 
  =====================
  Signin Css Start
  =====================
  */

.text-mute {
  color: #929495;
  font-family: "proxima_novathin";
  padding-top: 15px;
}

.sign-title {
  font-size: 40px;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
}

.form-main-box {
  max-width: 350px;
  width: 100%;
}

.form-main-box a {
  color: var(--dashboard-blue) !important;
}

.h-100 {
  height: 100%;
}

.min-100px {
  min-width: 100px !important;
}

.forgot-password {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer !important;
}

.signupbutton {
  text-transform: capitalize !important;
  background-color: #15a07d !important;
  height: 56px;
  color: #fff !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  font-family: "proxima_novasemibold" !important;
}

.signupbutton a {
  color: #fff;
  text-decoration: none;
  font-family: "proxima_novasemibold" !important;
}

.Signup-box {
  padding: 120px;
  padding-top: 30px;
  padding-left: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

w-100 {
  width: 100%;
}

h-100 {
  height: 100%;
}

/* 
  =====================
  Signin Css End
  */

/* 
  ========================
  Media query css start  
  ========================
  */

.content-footer {
  margin-top: 40px;
}

.personal-info p {
  color: #3d4244;
  line-height: 15px;
}

#personal-info label.label-txt {
  display: block;
  margin-bottom: 16px;
}

/* timesheet modal css  */

.time-sheet-modal .MuiPaper-elevation {
  width: 100% !important;
  max-width: 631px !important;
}

.time-sheet-modal .MuiPaper-elevation .MuiDialogContent {
  padding: 0;
}

.time-sheet-modal .MuiPaper-elevation .MuiDialogContent .mapouter {
  min-height: 323px;
  height: 100%;
}

.time-sheet-modal .MuiPaper-elevation {
  background: none !important;
  box-shadow: none !important;
}

.mtable-height {
  max-height: 500px;
  overflow-y: scroll;
}

.makeStyles-dialogactionbtn-11 {
  background-color: #15a07d !important;
}

.tableHeader .input-group {
  position: absolute;
  bottom: -18px;
  left: 0;
  min-width: 222px;
  width: 100%;
  height: 32px;
  z-index: 999;
  padding: 0 5px;
  border-radius: 4px;
  border: 1px solid #15a07d;
  background: #fff;
}

@media (max-width: 1320px) {
  .container {
    max-width: 1240px;
  }

  :root {
    /* Typography */
    --h2-size: 24px;
    --h3-size: 20px;
    --f-size-20: 16px;
    --f-size-16: 14px;
    --h1-size: 40px;
  }

  .card-content .card-text span {
    font-size: 13px;
  }

  #personal-info .profile-info {
    padding: 40px 20px 20px 20px !important;
  }

  /* #sidebar {
        width: 222px !important;
    } */

  .profile-info-heading {
    padding-left: 20px;
  }

  #personal-info .personal-info {
    padding: 30px 20px 8px 20px;
  }

  #personal-info .form-heading-section {
    padding: 10px 0px;
  }

  #personal-info .personal-info-main {
    margin-bottom: 30px;
  }

  .mtable-height {
    max-height: 400px;
    overflow-y: scroll;
  }

  .table-bottom-content {
    padding-bottom: 20px;
  }

  .mtable-height thead th {
    padding: 6px 5px;
  }

  .content-footer {
    margin-top: 20px;
  }

  .table-main {
    padding: 10px 48px 0 48px;
  }
}

@media (max-width: 1199px) {
  :root {
    /* Typography */
    --p-font-size: 14px;
    --f-size-14: 12px;
    --f-size-20: 16px;
  }
}

@media (max-width: 991px) {
  #stepper .step-lable .content {
    display: none;
  }

  #stepper .form-box {
    width: auto;
  }

  .sm-none {
    display: none !important;
  }

  .menu-toggle {
    display: block !important;
  }

  .navbar-menu {
    display: none !important;
  }

  #personal-info p {
    font-size: 14px !important;
  }

  .thankyou-page {
    left: 100px;
    transform: translate(0, -50%);
    right: 100px;
  }
}

@media (max-width: 768px) {
  :root {
    --h3-size: 16px;
  }

  #stepper .stepper {
    padding-left: 0 !important;
  }

  .container {
    padding: 0 10px !important;
  }

  #sidebar {
    /* width: 80px !important; */
    margin: auto !important;
  }

  .thankyou-page {
    left: 20px;
    transform: translate(0, -50%);
    right: 20px;
  }

  #personal-info .personal-info {
    padding: 30px 20px;
  }

  #personal-info .form-heading-section {
    padding: 10px 0;
  }
}

@media (max-width: 576px) {
  :root {
    --h1-size: 24px;
    --h2-size: 28px;
    --h3-size: 20px;
  }

  .xs-none {
    display: none !important;
  }
}

.activeMenu {
  background-color: #70acdb !important;
}

.table-primary th {
  border: 1px solid #d0ece5;
  background-color: #e6e6e6;
}

.table-primary td {
  border: 1px solid #d0ece5;
  font-family: "Open Sans", sans-serif;
}

.table-primary td p {
  font-size: 0.875rem;
  font-family: "Open Sans", sans-serif;
}

#table .table-body td {
  padding: 20px 16px;
  height: auto;
}

/* font changes  */

.sidebar-item-link span,
.dashboard-form-typo p,
.dashboard-form .time-filed input::placeholder,
.table-menu,
.table-actionbtn span,
.table-time-text span,
.dashboard-form .table-edit-del a,
#appointment-table .appointment-cell,
#document-table .table-head-cell,
#appointment-table .table-head-cell,
#Appointment-Dashboard .approved-leave-section .addTask-btn a,
.table-primary th,
::placeholder,
.sidebar-item span,
.table-time-text span,
.card-text-icons.card-icons,
.dashboard-form .agreement-header h2,
.dashboard-form .agreement-check p,
.del-btn a,
.client-detail-modal p,
.doc-search::placeholder,
.menu-link,
.hide-client-img-box p,
.hide-content p,
.hide-content a,
.team-popover-text p,
.team-popover-text span,
.team-popover-text p,
.time-cell p,
.time-cell span,
.agreement-body .form-check-label,
.routine-sub-box-text span,
#Appointment-Dashboard .approved-leave-section .tasksAssigned p,
#Appointment-Dashboard .approved-leave-section .tasksAssigned span,
.note-popover p,
.gallary-dialog-head .arrow-slide-box span,
.gallary-dialog-head .arrow-slide-box div,
.gallary-dialog-head p,
.swapped-text span,
.taskPopover p,
.view-all,
.list-image-detail p,
.notes-profile p,
.list-note-description p,
.list-note-description span,
.list-note-description p span,
.view-more-notes,
#listNote .list-update-btn,
#AddTask .Add-Task-cancel,
#calender .fc-col-header-cell-cushion,
#calender .calender-date-cell,
#calender .calender-date-cell p,
#calender .calender-client-cell p,
#calender .calender-client-cell span,
#calender .calender-client-cell span,
.taskTitle,
.hide-content-number a,
.progress-date p,
.leave-boxs p,
.workType-text p,
.timesheet-table .Expenses-cell tbody tr td a,
.timesheet-table .expenses-add,
.timesheet-table .total-unit,
.add-expenses-body .add-expenses-input-box label,
.attach-file-input input::before,
.table-link,
.dropdown-menu a,
.agency-searchbar.agency-searchbar-custom .client-btn {
  font-family: "proxima_novaregular";
}

#appbar .text,
.dashboard-form .form-text,
.notification-text,
.appbar-switch p,
.bg-strip p,
label,
.importexportbtn,
#appbar .app-worker-name p,
.shift-type-check span,
.dashboard-form .shift-select .MuiSelect-select em,
.weak-name p,
#document .personal-doc-btn span,
#document .bankdetails-btn span,
.checkbox-label-box .checkbox-label,
#document .personal-doc-btn span,
.routine-stepper-box .routine-chip,
.routine-stepper-box .stepper-add-task-btn,
.routine-stepper-box .add-task-item p,
.table-icon-popover p,
.appointboxheader .Header-title,
.note-title h3,
.appointment-dashboard-profile p,
.appointment-dashboard-timebar p,
.swapped-text p,
.hide-client-img-box a,
.Team p,
.Team span,
.team-details p,
.team-details span,
.approved-without-pay p,
.generate-timesheets,
.generate-timesheets a,
#add-expenses .dashboard-form-typo span,
.add-expenses .add-expenses-head p,
.add-expenses-body .attach-document h3,
.client-btn a,
#appbar .app-worker-name span,
.agency-btn-section a,
#document-table .table-btn,
.task-attachment h3,
.access-attachment span,
.access-attachment h3,
.client-task-avtar-text span,
.dashboard-form .tab-label,
.client-detail-modal span,
#client-management .task-tab-row-typo {
  font-family: "proxima_novasemibold";
}

.dashboard-form-typo h3,
.agency-btn-section-vk button,
.client-btn a,
.appbar-switch p,
.sidebar-porfile-content p,
.shift-type-section .appbar-switch p,
.ChangePasswordtext.MuiTypography-root,
.agency-btn-section .saveBtn,
.account-setup-footer p,
.carousel-container .carousel-text,
.sign-title,
.headertext-sty.MuiTypography-root,
.dashboard-form .agency-form-box-btn-custom a,
.empid-label-box span,
.appbar-switch p,
.arrowbacktext.MuiTypography-root,
.card-content .card-text span,
.skill-typo.MuiTypography-root,
#pagination li:last-child button,
.table-meeting-cell span,
#add-appointment .add-routine-btn,
.badgeTxt,
.map-details p,
.map-details span,
.map-details a,
.finish-btn,
.data-csv,
.client-task-avtar-text p,
.table-meeting-cell span {
  font-family: "proxima_novabold";
}

.table-primary th h5,
.sidebar-porfile-content span,
.appointment-dashboard-profile span {
  font-family: "proxima_novathin";
}

.input-field-padding {
  padding: 8.5px 14px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.radio-box-wrraper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 230px;
}

.position-static {
  position: static !important;
}

/* event popover css  */

.fc-popover {
  visibility: hidden !important;
}

.event-popup-custom {
  min-width: 220px;
  height: 250px;
  overflow-y: scroll;
  background-color: #fff;
}

.event-close-icon {
  width: 20px;
}

.event-status-img {
  width: 20px;
}

.event-close-icon img {
  width: 100%;
}

.event-popup-bar {
  background-color: #f1f1f1;
  padding: 4px 10px;
  position: sticky;
  top: 0;
  left: 0;
}

.event-pop-wrap {
  padding: 20px;
}

.event-popover .calender-client-cell {
  width: auto !important;
  display: inline-block !important;
  margin-bottom: 10px;
}

.event-popover .MuiPaper-root {
  border-radius: 0 !important;
  box-shadow: 0 0 10px gray;
  border: 0.8px solid gray;
}

.event-close-icon {
  cursor: pointer;
}

.event-popover .MuiBox-root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.calenderdata-popup .mui-select-wrapper {
  width: 100%;
}

/* _____________ */

.MuiChartsLegend-series {
  display: none !important;
}

.gest-onboar-step4 {
  object-fit: contain !important;
}

.fc-popover-body {
  display: none !important;
}

.MuiIcon-root {
  cursor: pointer !important;
}

.MuiSvgIcon-root {
  cursor: pointer !important;
}