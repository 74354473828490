#table-exp {
  width: 100%;
}

#timesheet-expnses {
  /* height: 83px; */
  height: 75px;
}

.routine-overflow-custom {
  margin-bottom: -6px !important;
}

.view-all-div-custom {
  margin-bottom: 30px;
}

.timesheet-border-none {
  border-bottom: none !important;
}

.timesheet-custom-total {
  color: #15a07d !important;
  font-size: 16px !important;
}

#timesheet-expnses.routine-overflow-hide {
  overflow-y: scroll;
  height: 239px;
}

.view-all span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.routine-overflow + .view-all .less {
  display: none;
}

.routine-overflow-hide + .view-all .viewall {
  display: none;
}

.eye-show-hide {
  position: absolute;
  right: 4px;
}

.hide-button {
  display: none !important;
}

.timesheet-input-fields {
  padding: 0 80px 0 35px;
  margin-top: 50px;
}

.approved-leave-section {
  background-color: var(--ex-light-gray);
  padding: 20px 80px 20px 35px;
  box-shadow: none;
  width: 100%;
}

.approved-heading {
  cursor: pointer;
}

.approved-heading svg {
  margin-left: 20px;
}

.approved-heading svg path {
  fill: var(--main-dark-color);
}

.leave-box-img {
  width: 20px;
}

.leave-boxs p {
  font-size: 14px;
  font-weight: 400;
  font-family: "proxima_novasemibold";
  color: #3d4244;
  padding-left: 8px;
}

.leave-boxs {
  margin: 10px 10px 10px 10px;
}

.leave-avtar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.approved-without-pay p {
  font-size: 16px;
  font-weight: 500;
  color: #3d4244;
  font-family: "proxima_novasemibold";
}

.approved-detail {
  padding: 0 80px 0 35px;
  margin: 50px 0;
  transition: all 0.2s ease-in-out;
}

.approved-without-pay {
  margin-right: 10px;
}

.pay-date span {
  background-color: var(--ex-light-gray);
  padding: 6px 7px;
  border-radius: 6px;
  margin: 0 10px;
}

.approved-note {
  color: #929495;
  font-size: 16px;
  font-family: "proxima_novasemibold";
  padding-top: 20px;
}

.generate-timesheets {
  font-size: 16px;
  color: #3d4244;
  font-family: "proxima_novasemibold";
  font-weight: 600;
  padding-top: 20px;
}

.generate-timesheets a {
  color: #15a07d;
  font-size: 16px;
  font-weight: 600;
  font-family: "proxima_novasemibold";
}

.workType-text p {
  color: #929495;
  font-size: 13px;
  font-weight: 400;
  font-family: "proxima_novasemibold";
}

.table-time span {
  font-size: 13px;
}

#timesheet .table-head-cell {
  /* width: 100px; */
  background: #e8f6f2 !important;
  color: #929495 !important;
}

.timesheet-table {
  overflow-x: auto;
  white-space: nowrap;
  /* -webkit-overflow-scrolling: touch; */
}

.timesheet-table table {
  width: auto;
  /* white-space: nowrap; */
}

.timesheet-table th,
.timesheet-table td {
  padding: 10px;
}

/* .timesheet-table1 {
  white-space: nowrap;
} */

/* .timesheet-table::-webkit-scrollbar {
  display: none;
} */

.timesheet-table #document-table {
  overflow-x: scroll !important;
}

.timesheet-table #document-table tbody tr:nth-child(3) {
  background: none;
}

.rotate {
  transform: rotate(90deg) !important;
}

.approved-btn {
  transition: all 0.4 ease-in-out !important;
}

.map-btn a {
  color: #71ba60 !important;
}

.timesheet-table .Expenses-cell {
  padding: 0 !important;
}

.timesheet-table .Expenses-cell table {
  margin-bottom: -5px !important;
  height: 100% !important;
}

.timesheet-table .table-head-cell {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

#timesheet .timesheet-table .Expenses-cell table thead tr th {
  min-width: 100px;
  text-align: center;
  padding: 5px 0;
  background-color: #e8f6f2;
  color: #929495 !important;
}

#timesheet .timesheet-table .Expenses-cell tbody tr td {
  background-color: transparent !important;
}

.timesheet-table .Expenses-cell tbody tr td a {
  color: #3d4244;
  font-size: 13px;
  /* font-weight: 400; */
  /* font-family: "proxima_novasemibold"; */
}

.expenses-td {
  width: 100px;
}

.time-cell.MuiTypography-root {
  color: #3d4244;
  font-size: 13px;
  text-align: left;
}

.time-cell span {
  color: #ff5251 !important;
}

.timesheet-table .expenses-add {
  color: var(--main-dark-color);
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 400;
  text-decoration: none;
  font-family: "proxima_novasemibold";
}

.timesheet-table .expenses-add svg {
  margin-right: 10px;
}

.timesheet-table .expenses-add svg path {
  color: var(--main-dark-color);
}

.timesheet-table .pdf-icon {
  width: 20px;
  height: 20px;
}

.timesheet-table .total-unit {
  /* width: 60px; */
  color: #3d4244;
  font-size: 13px;
  /* font-weight: 400; */
  /* font-family: "proxima_novasemibold"; */
  /* width: 100%; */
  /* height: 100%; */
  /* justify-content: center; */
}

#timesheet .mapouter {
  position: relative;
  text-align: right;
  height: 100%;
  width: 100%;
}

#timesheet .gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 100%;
  width: 100%;
}

.map-modal button {
  color: #15a07d;
  background: none;
  border: 10px;
  width: 139px;
  border: 1px solid #15a07d;
}

#timesheet .modal-content p {
  padding-bottom: 0 !important;
}

.work-type-td {
  min-width: 164px !important;
}

.table-h-66 {
  height: 66px !important;
}

.table-profile-image2 {
  width: 59px !important;
  height: 59px;
  margin-right: 5px;
  object-fit: cover;
}

.table-profile-image {
  width: 100px;
  height: 55px;
}

/* .table-profile-image img {
  width: 100%;
  object-fit: cover;
} */

/* 
========================
add expenses page css  
========================
*/

#add-expenses .dashboard-form-typo {
  padding-right: 80px;
}

#add-expenses .dashboard-form-typo span {
  color: #3d4244;
  font-weight: 500;
  font-family: "proxima_novasemibold";
  font-size: 16px;
}

.add-expenses {
  max-width: 546px;
  margin: 32px auto 0 auto;
  width: 100%;
  box-shadow: 0px 9px 15px rgba(22, 22, 22, 0.1);
  border-radius: 0px 0px 8px 8px;
}

.add-expenses-policy {
  max-width: 900px;
  margin: 32px auto 0 auto;
  width: 100%;
  box-shadow: 0px 9px 15px rgba(22, 22, 22, 0.1);
  border-radius: 0px 0px 8px 8px;
}

.add-expenses .add-expenses-head {
  background-color: var(--ex-light-gray);
  padding: 8px 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.add-expenses-policy .add-expenses-head {
  background-color: var(--ex-light-gray);
  padding: 8px 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.add-expenses .add-expenses-head p {
  color: #3d4244;
  font-size: 16px;
  font-weight: 500;
  font-family: "proxima_novasemibold";
}

.add-expenses .add-expenses-head span {
  color: #929495;
}

.add-expenses-body {
  padding: 32px;
}

.add-expenses-body .add-expenses-input-box {
  margin-top: 40px;
}

.add-expenses-body .add-expenses-input-box label {
  padding-bottom: 15px;
  font-size: var(--f-size-16);
  font-weight: 400;
  font-family: "proxima_novasemibold";
  color: #3d4244;
}

.add-expenses-body .attach-document h3 {
  font-size: var(--f-size-16);
  font-weight: 400;
  font-family: "proxima_novasemibold";
  color: #3d4244;
  padding-top: 32px;
}

.attach-document-body {
  padding: 12px 4px;
  border-radius: 4px;
  margin-top: 4px;
  background: rgba(21, 160, 125, 0.1);
}

.attach-document-body img {
  width: 32px;
  height: 32px;
  margin: 0 8px;
}

.attach-document-body .d-flex {
  width: 50%;
}

.attach-document-browse button {
  min-width: 87px;
  background-color: var(--ex-light-gray);
  padding: 8px 0;
  text-align: center;
  border-radius: 4px;
  color: var(--main-dark-color);
}

.attach-document-browse {
  flex-direction: row !important;
  margin-top: 16px !important;
}

.attach-document-browse .MuiTextField-root {
  max-width: calc(100% - 115px);
  width: 100%;
}

.attach-file-input input::-webkit-file-upload-button {
  visibility: hidden;
}

.attach-file-input input {
  position: relative;
}

.attach-file-input input::before {
  content: "Browse";
  cursor: pointer;
  color: #929495;
  font-size: 13px;
  font-weight: 400;
  font-family: "proxima_novasemibold";
  width: 51px;
  border-radius: 4px;
  text-align: center;
  padding: 0px 6px;
  background-color: #f5f5f5;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.modal-text {
  padding: 0 !important;
}

.modal-text .add-expenses {
  margin-top: 0 !important;
}

.distance-txt {
  padding-bottom: 0 !important;
}

.map-modal-footbar {
  padding: 15px 30px;
}

#add-expenses .add-expenses-btn {
  margin: 47px 0 0 0;
}

#add-expenses .add-expenses-btn a {
  text-decoration: none;
}

/* .no-timer-icon::-webkit-calendar-picker-indicator {
  display: none;
} */

#timesheetAdmin .table-head-cell:nth-child(1) .fliter-img,
#timesheetAdmin .table-head-cell:nth-child(2) .fliter-img,
#timesheetAdmin .table-head-cell:nth-child(4) .fliter-img,
#timesheetAdmin .table-head-cell:nth-child(5) .fliter-img,
#timesheetAdmin .table-head-cell:nth-child(6) .fliter-img,
#timesheetAdmin .table-head-cell:nth-child(7) .fliter-img,
#timesheetAdmin .table-head-cell:nth-child(8) .fliter-img,
#timesheetAdmin .table-head-cell:nth-child(9) .fliter-img,
#timesheetAdmin .table-head-cell:nth-child(10) .fliter-img,
#timesheetAdmin .table-head-cell:nth-child(11) .fliter-img {
  display: none;
}

#timesheetAdmin .filter-search-field:nth-child(1) .input-group,
#timesheetAdmin .filter-search-field:nth-child(2) .input-group,
#timesheetAdmin .filter-search-field:nth-child(4) .input-group,
#timesheetAdmin .filter-search-field:nth-child(5) .input-group,
#timesheetAdmin .filter-search-field:nth-child(6) .input-group,
#timesheetAdmin .filter-search-field:nth-child(7) .input-group,
#timesheetAdmin .filter-search-field:nth-child(8) .input-group,
#timesheetAdmin .filter-search-field:nth-child(9) .input-group,
#timesheetAdmin .filter-search-field:nth-child(10) .input-group,
#timesheetAdmin .filter-search-field:nth-child(11) .input-group {
  display: none !important;
}

#timesheetAdmin .tableHeader .input-group {
  bottom: -30px;
}

#timesheetAdmin .timesheet-cell-arrow {
  width: 24px;
}

#timesheetAdmin .timesheet-cell-arrow svg {
  width: 100%;
  object-fit: cover;
  transform: rotate(-90deg);
}

#timesheetAdmin .timesheet-cell-arrow svg path {
  fill: #15a07d;
}

#timesheetAdmin .rotate-arrow {
  transform: rotate(0deg) !important;
}

.table-sub-user {
  padding-left: 21px !important;
}

.no-timer-icon {
  padding-left: 4px;
  padding-right: 2px;
  border: 2px solid #15a07d;
  border-radius: 4px;
}

#timesheet-filter label {
  font-size: 14px;
}

#timesheet-filter .datelabel {
  font-size: 14px;
  padding-right: 0 !important;
  width: 98px;
}

@media (max-width: 1320px) {
  #add-expenses .dashboard-form-typo {
    padding-right: 50px;
  }

  .approved-leave-section {
    padding-right: 50px;
  }

  .timesheet-input-fields {
    padding-right: 50px;
  }

  .approved-detail {
    padding-right: 50px;
  }
}

@media (max-width: 991px) {
  .approved-leave-section-res {
    flex-direction: column-reverse !important;
    align-items: flex-start !important;
  }

  .approved-head {
    margin-top: 20px;
  }

  .txt-left-res {
    text-align: left !important;
  }
}

@media (max-width: 768px) {
  .approved-detail > .d-flex {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .approved-without-pay {
    margin-bottom: 10px;
  }

  .timesheet-input-fields {
    padding-right: 20px;
    padding-left: 20px;
    margin-top: 30px;
  }

  .dashboard-form-typo h4 {
    font-size: 16px;
  }

  #add-expenses .dashboard-form-typo {
    flex-direction: column;
    align-items: flex-start !important;
  }

  #add-expenses .dashboard-form-typo p {
    padding-top: 10px;
  }

  .add-expenses {
    margin: 35px 0px 0 0px;
  }

  .add-expenses-body .add-expenses-input-box label {
    margin-top: 10px;
  }

  .add-expenses-body .add-expenses-input-box {
    margin-top: 18px;
  }

  .add-expenses-p-10 {
    padding: 0 10px;
    margin-bottom: 40px;
  }
}

@media (max-width: 576px) {
  .available-leave-section .available-leave {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .available-leave-section .available-leave .leave-boxs .text-center {
    text-align: left !important;
  }

  .available-leave-section .available-leave .leave-boxs .leave-inner-box {
    justify-content: flex-start !important;
  }

  .approved-detail {
    padding: 20px;
    margin: 0;
  }

  .pay-date {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .pay-date > div {
    margin-bottom: 10px;
  }

  .pay-date span {
    margin: 0;
  }

  .leave-boxs {
    margin: 10px 0;
  }

  .approved-note {
    font-size: 14px;
  }

  .generate-timesheets {
    font-size: 14px !important;
  }

  .flex-column-res {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .attach-document-browse .MuiTextField-root {
    max-width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 484px) {
  .add-expenses-body {
    padding: 20px;
  }

  .attach-document-body {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .attach-document-body .d-flex:nth-child(2) {
    padding-left: 0;
    margin-top: 10px;
  }

  #add-expenses .add-expenses-btn a {
    max-width: 100%;
    width: 100%;
    margin-bottom: 10px;
  }
}

.modal-content-pri p {
  font-size: 12px !important;
}

.calender-add-icon:hover .table-icon-privk-popover {
  display: flex !important;
  width: auto;
  padding-left: 4px;
  padding-right: 4px;
}

.table-icon-privk {
  height: 20px;
  margin: auto;
  position: relative;
  margin-left: 0;
}

.table-icon-privk-popover {
  position: absolute;
  top: -39px;
  left: 90px;
  width: 100px;
  border-radius: 4px;
  padding: 4px 0;
  background-color: #fff;
  z-index: 999;
  box-shadow: 0px 4px 15px rgba(22, 22, 22, 0.2);
  display: none !important;
}

.table-icon-privk .team-popover-arrow {
  bottom: -32px;
}

.table-icon-privk .font-text {
  font-size: 12px !important;
}

.table-icon-privk:hover .table-icon-privk-popover {
  display: flex !important;
}

.table-icon-privk-popover img {
  width: 20px !important;
  margin-right: 8px;
}

.table-icon-privk-popover p {
  font-size: 16px;
  font-weight: 500;
  font-family: "proxima_novasemibold";
}

.table-icon-privk img {
  width: 100%;
  height: 100%;
}
