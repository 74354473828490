.webContainer {
  max-width: 1280px;
  margin: auto;
}

.home-manage-section h1 {
  padding: 0 15px;
}

.web-topBanner-bg {
  background: linear-gradient(0deg,
      rgba(21, 160, 125, 0.05) 0%,
      rgba(21, 160, 125, 0.05) 100%),
    #fff;
}

.webHeader-logo {
  width: 90px;
  height: 90px;
}

.menu-icons {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.menu-icons img {
  width: 100%;
}

.menu-drop-text p {
  color: #202e2a;
  font-size: 18px;
  font-family: "proxima_novasemibold";
  line-height: normal;
  /* 27px */
}

.menu-drop-text span {
  color: #4e4e4e;
  font-family: "proxima_novasemibold";
  font-size: 14px;
  line-height: 150%;
  /* 21px */
}

.webmenu-dropdown .MuiPaper-root {
  padding: 32px;
  border-radius: 16px;
  background: var(--More-Color-White, #fff);
  box-shadow: 0px 8px 40px 0px rgba(5, 49, 38, 0.12);
  margin-top: 20px;
}

.webmenu-dropdown ul li {
  padding-left: 6px !important;
  padding-right: 6px !important;
  margin-top: 16px;
  transition: all 0.2s ease-in-out;
}

.webmenu-dropdown ul li:hover {
  background: var(--Primary_Light_10, rgba(21, 160, 125, 0.1));
}

.webHeader-logo img {
  width: 100%;
  height: 100%;
}

.web-header {
  /* padding: 10px 0; */
  /* border: 1px solid #f9f9f9;
  box-shadow: 0 0 10px #f1f1f1; */
  position: relative;
  /* z-index: 999; */
}

.menu-dropsub-title {
  color: #8c8c8c;
  font-size: 12px !important;
  font-family: "proxima_novasemibold";
  line-height: 150%;
  /* 18px */
  letter-spacing: 0.5px;
  text-transform: uppercase !important;
}

.mainHome .web-header {
  border: none;
  box-shadow: none;
}

.header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  border: 1px solid #f9f9f9 !important;
  box-shadow: 0 0 20px #f1f1f1 !important;
  background-color: #ffffff !important;
}

#webHeader .header-menu-link {
  color: #202e2a;
  font-size: 18px;
  font-family: "proxima_novasemibold";
  margin-right: 40px;
  text-decoration: none;
}

#webHeader .header-menu-link button {
  color: #202e2a;
  font-size: 18px;
  text-transform: capitalize !important;
  font-family: "proxima_novasemibold";
  background: none !important;
  display: flex;
  align-items: center;
}

#webHeader .sign-in-button {
  padding: 0 22px;
  height: 44px;
  border-radius: 8px;
  background: #645dd7;
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-right: 0 !important;
  margin-left: 20px !important;
  color: #fff;
  font-family: "proxima_novasemibold";
  transition: all 0.1s ease-in-out !important;
}

#webHeader .sign-in-button:hover {
  background: #726ceb;
}

#webHeader .book-demo-button:hover {
  background-color: #30b694;
  color: #fff;
}

.webHeader-menubar {
  width: calc(100% - 92px);
}

.web-header-select {
  height: 45px !important;
  max-width: 160px !important;
}

.header-select {
  background-color: #fff !important;
}

.webHeader-menubar-signUp {
  color: #645dd7;
  font-size: 16px;
  line-height: normal;
  font-family: "proxima_novaregular";
  text-decoration: underline;
  margin-bottom: 16px;
}

.web-primary-btnLarge {
  display: flex;
  width: 200px;
  height: 56px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: #fff !important;
  font-size: 18px;
  text-decoration: none !important;
  font-family: "proxima_novasemibold";
  margin-left: 16px;
  cursor: pointer;
}

.cta1 {
  background-color: #15a07d !important;
}

.cta2 {
  background: #645dd7;
}

.home-content-main h1 {
  font-size: 60px;
  color: #202e2a;
  line-height: 100%;
  font-family: "proxima_novabold";
}

.home-content-main p {
  color: #4e4e4e;
  font-size: 20px;
  line-height: normal;
  font-family: "proxima_novasemibold";
  padding: 40px 0;
}

.home-banner {
  width: 88%;
  height: 75%;
}

.home-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.homeBanner-main-container {
  width: 100%;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  padding: 120px 0;
}

.homeBanner-main-1 {
  background-image: url(../../assets/images/heroslider1.png);
}

.homeBanner-main-2 {
  background-image: url(../../assets/images/heroslider2.png);
}

.homeBanner-main-3 {
  background-image: url(../../assets/images/heroSlider6.png);
}

.homeBanner-main-4 {
  background-image: url(../../assets/images/heroSlider5.png);
}

.main-banner .owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  position: absolute;
  left: 20px;
  right: 20px;
  display: flex !important;
  justify-content: space-between;
  top: 50%;
  margin-top: -30px;
}

.main-banner .owl-carousel .owl-nav button.owl-prev,
.main-banner .owl-carousel .owl-nav button.owl-next,
.main-banner .owl-carousel button.owl-dot {
  font-size: 35px;
  color: #202e2a;
}

.main-banner .owl-theme .owl-nav [class*="owl-"]:hover {
  background: none;
}

/* get support page css  */

.getSupport-banner {
  padding-left: 122px;
}

.get-support-filter {
  padding-left: 60px;
}

.get-support-filter-button {
  display: flex;
  width: 180px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #15a07d;
  color: #fff;
  font-size: 16px;
  font-family: "proxima_novabold";
}

/* worker card css  */

.worker-cards-main-heading {
  font-size: 20px !important;
  color: #202e2a;
  font-family: "proxima_novabold" !important;
  padding: 40px 0;
}

.worker-card-main {
  border-radius: 12px !important;
  box-shadow: 0px 0px 5px 0px rgba(22, 22, 22, 0.2) !important;
}

.woker-card-header {
  align-items: flex-start !important;
}

.worker-card-name {
  color: #202e2a;
  font-family: "proxima_novabold" !important;
  font-size: 20px;
  text-overflow: ellipsis;
  width: 152px;
  overflow: hidden;
  text-wrap: nowrap;
  margin-right: 6px !important;
}

.worker-card-rating {
  height: 20px;
}

.worker-card-rating .rating-cion-sty {
  width: 14px;
}

.worker-card-rating .rating-cion-gray {
  width: 14px;
}

.worker-card .explore-icon {
  width: 80px !important;
  height: 80px !important;
}

.worker-card .explore-icon img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.card-location-text {
  max-width: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  /* text-wrap: nowrap; */
  white-space: nowrap;
  font-size: 14px;
  font-family: "proxima_novaregular" !important;
  line-height: 13px;
}

.worker-map-icon {
  width: 14px;
}

.worker-card-explore-icon path {
  fill: #fc7a57;
}

.chip-show {
  font-size: 13px;
  font-family: "proxima_novaregular";
  color: #645dd7;
  display: flex;
  align-items: center;
}

.worker-card-chip {
  margin-bottom: 10px;
  margin-left: 0 !important;
  margin-left: 8px !important;
  background: var(--Primary_Light_20, rgba(21, 160, 125, 0.2)) !important;
}

.worker-card-chip span {
  color: #15a07d;
}

.card-availability-icon {
  width: 16px;
  margin: 0 5px;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.card-send-icon {
  width: 24px;
}

.worker-card .fav-iconbtn {
  border: none;
  width: 20px;
}

.show-result-box span {
  color: #fff;
  font-size: 16px;
  font-family: "proxima_novasemibold";
}

.show-result-box img {
  width: 16px;
}

.show-result-box {
  background-color: #15a07d;
  padding: 8px;
  border-radius: 4px;
  justify-content: space-between;
  width: 58px;
}

.header-select-link {
  text-decoration: none !important;
  color: #202e2a !important;
  font-size: 14px !important;
  font-family: "proxima_novaregular";
}

/* ======================
view details css start 
====================== */

.view-details-wrraper h3 {
  font-size: 24px;
  color: rgba(0, 0, 0, 1);
  font-family: "proxima_novabold" !important;
}

.view-details-wrraper {
  padding: 56px 0;
}

.view-details-main-box {
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(22, 22, 22, 0.12);
  /* padding: 32px 0; */
  padding: 10px 0;
}

.view-detail-profile-image {
  width: 100px;
  border-radius: 50%;
}

.view-detail-profile-image img {
  width: 100%;
  object-fit: cover;
}

.view-profile-detail {
  padding: 0 57px;
}

.view-detail-profile-content {
  padding-left: 16px;
}

.view-detail-profile-content .profile-title {
  color: #202e2a;
  font-size: 20px;
  padding-right: 16px;
  font-family: "proxima_novabold" !important;
  line-height: 100%;
}

.view-detail-rating-box label {
  color: #fdd443 !important;
}

.view-porfile-category {
  color: #8c8c8c;
  font-size: 14px;
  font-family: "proxima_novaregular";
}

.profile-content {
  color: #8c8c8c;
  font-size: 13px;
  font-family: "proxima_novaregular";
  display: contents;
}

.profile-content span:nth-child(2) {
  color: #8c8c8c;
  font-size: 13px;
  font-family: "proxima_novaregular";
  display: flex;
}

.profile-content span:nth-child(3) {
  color: red;

  align-items: center;
  display: flex;
}

.profile-content-new {
  color: #8c8c8c;
  font-size: 13px;
  font-family: "proxima_novaregular";
  /* display: contents; */
  margin-top: 10px;
}

.profile-content-new span:nth-child(2) {
  color: #8c8c8c;
  font-size: 13px;
  font-family: "proxima_novaregular";
  display: flex;
  margin-bottom: 10px;
}

.profile-content-new span:nth-child(3) {
  color: red;

  align-items: center;
  display: flex;
}

.view-details-main-box .view-detail-client-address img {
  width: 18px;
}

#view-details .view-details-main-box .view-detail-client-address {
  margin-top: 12px;
}

#view-details .view-details-main-box .view-detail-client-address .location-txt {
  color: #202e2a;
  font-size: 14px;
  font-family: "proxima_novaregular";
}

#view-details .view-details-main-box .exp-txt {
  color: #454545;
  font-size: 14px;
  font-family: "proxima_novaregular";
  padding-left: 2px;
}

.view-tap-box {
  /* padding: 20px 120px; */
  padding: 20px 54px;
}

.about-panel-txt p {
  color: #202e2a;
  font-size: 16px;
  line-height: normal;
  font-family: "proxima_novasemibold";
}

.about-panel-txt span {
  color: #8c8c8c;
  font-size: 16px;
  line-height: normal;
  font-family: "proxima_novasemibold";
  padding-left: 4px;
}

.view-detail-panel {
  padding: 24px 0;
}

.view-tab-chip .MuiChip-filled {
  background-color: rgba(21, 160, 125, 0.1);
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 10px;
}

.view-tab-chip .MuiChip-filled span {
  color: #15a07d;
}

.google-map {
  width: 100%;
}

.google-map img {
  width: 100%;
  object-fit: cover;
}

.view-details-button button {
  padding: 16px 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  border: 1px solid #e3e8e6;
  background: #fff;
  margin: 0 12px;
  font-size: 16px;
  font-family: "proxima_novasemibold";
  cursor: pointer;
}

.view-details-button button img {
  width: 24px;
  margin-right: 12px;
}

.view-details-button .success-button {
  background-color: #15a07d;
  color: #fff;
  border: none;
}

.view-details-button {
  margin-top: 40px;
}

/* document tab css  */

.document-tab-box {
  border-radius: 4px;
  border: 1px solid var(--stroke-border, #e3e8e6);
  padding: 40px 32px;
}

.document-tab-icon {
  width: 18px;
  margin-right: 16px;
}

.document-tab-icon img {
  width: 100%;
  object-fit: cover;
}

.document-tab-content p {
  font-size: 16px;
  color: #202e2a;
  font-family: "proxima_novaregular";
  line-height: 100%;
}

.document-tab-content span {
  font-size: 16px;
  color: #8c8c8c;
  line-height: 100%;
  font-family: "proxima_novaregular";
}

.request-documents-button {
  color: #645dd7;
  font-size: 16px;
  font-family: "proxima_novasemibold";
  display: flex;
  align-items: center;
  border: none;
  background-color: #fff;
  margin-left: auto;
  margin-bottom: 16px;
}

.text-right {
  text-align: right !important;
}

/* view details Availability css   */

.View-details-availability-cell p {
  color: #202e2a;
  font-size: 12px;
  line-height: 100%;
  font-family: "proxima_novaregular";
}

.view-details-availability-tCell {
  padding: 35px 10px !important;
}

.view-tap-box .tab-container {
  max-width: 604px !important;
  position: relative;
}

.view-tap-box .tab-container1 {
  max-width: 900px !important;
  position: relative;
}

.view-tap-box .tab-container::after {
  position: absolute;
  content: "";
  top: 49px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #e3e8e6;
  z-index: 1;
}

.view-tap-box .tab-container .view-tab {
  width: 200px;
  text-transform: capitalize;
}


.view-tap-box .tab-container .view-tab-request {
  width: auto !important;
  text-transform: capitalize;
}



.view-tap-box .tab-container .MuiTabs-flexContainer {
  justify-content: flex-start !important;
}

.view-tap-box .tab-container .Mui-selected {
  color: #15a07d;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 2px solid var(--Primary, #15a07d);
  background: var(--Primary_Light_10, rgba(21, 160, 125, 0.1));
}

.view-detail-panel-grid {
  margin-bottom: 24px !important;
  padding-bottom: 0 !important;
}

/* footer css  */

.webFooter-bg {
  background: #f6f6f6;
  padding: 90px 0px 30px 0;
  text-align: center;
}

.webFooter-bg h3 {
  color: #000;
  font-family: "proxima_novasemibold";

  font-size: 48px;
  font-style: normal;

  line-height: normal;
}

.blank-box {
  height: 600px;
}

.existing-box {
  height: 100vh;
}

.existing-box-client {
  height: 100vh;
}

.existing-looking {
  color: #8c8c8c;
  font-size: 20px;
  font-family: "proxima_novasemibold";
}

.existing-user-main .explore-icon {
  margin: 16px 0 24px 0;
}

.existing-user-name {
  font-size: 24px;
  font-family: "proxima_novasemibold";
  margin-right: 16px;
}

.explore-img {
  width: 24px;
  margin-right: 8px;
}

.existing-box .exp-txt {
  font-size: 16px;
  font-family: "proxima_novaregular";
  color: #454545;
}

.existing-box .exp-txt span {
  font-size: 16px;
  font-family: "proxima_novaregular";
  color: #000;
}

.exlpore-sleepover-img {
  margin-right: 8px;
  margin-left: 6px;
  width: 24px;
}

.existing-user-details {
  margin-top: 20px;
}

.existing-user-details-shift {
  margin-top: 16px;
}

.existing-box-sign-in {
  background: #fafafa;
}

.existing-box-shadow {
  box-shadow: 0px 1px 24px 0px rgba(237, 231, 225);
}

.grid-container {
  display: flex;
}

.grid-divider {
  color: grey;
  border-left: 1px solid #958989;
  /* Adjust the color and style as needed */
  height: 80%;
  /* Adjust the height as needed */
  margin-top: 10%;
  /* Adjust the margin to center the line vertically */
}

.circular-bar {
  color: #15a07d !important;
  position: relative;
  width: 68px !important;
  height: 68px !important;
}

.circular-bar svg {
  overflow: visible !important;
}

.circular-bar::after {
  content: "";
  position: absolute;
  top: 0;
  left: 1px;
  width: 56px;
  height: 56px;
  z-index: -1;
  border: 6px solid #bababa;
  border-radius: 50%;
}

.connection-tab-padding {
  padding: unset !important;
  margin-top: 20px;
}

.nav_fixed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #fff;
}

.Web-Header-Profile-Img {
  width: 40px;
  height: 40px;
}

.Web-Header-ProfileButton {
  min-width: 40px !important;
  height: 40px;
  padding: 0 !important;
}

.Webheader-menu-Profile img {
  width: 24px;
}

.Webheader-menu-Profile .MuiPaper-elevation1 {
  top: 84px !important;
}

.owl-carousel .owl-item img.banner-img {
  /* height: calc( 100vh - 112px ) !important; */
  height: calc(110vh - 112px) !important;
}

.book-demo-button {
  padding: 6px 16px;
  background-color: #15a07d;
  border-radius: 8px;
  color: #fff !important;
  height: 44px;
  display: flex;
  align-items: center;
  margin-right: 0 !important;
  transition: all 0.1s ease-in-out !important;
}

/* perosnal docuemnt  */

/* .css-1bgvevh-MuiContainer-root */

.per-doc.MuiContainer-root {
  padding-left: 0px;
}

.browes-files {
  color: #bababa !important;
}

.browes-icons {
  color: #bababa !important;
}

.handrad-point-btn.MuiButton-root {
  background-color: #15a07d !important;
  text-transform: capitalize;
  height: 35px !important;
}

.handrad-point-btn1.MuiButton-root {
  border: 1px solid #15a07d !important;
  text-transform: capitalize;
  color: #15a07d !important;
  height: 35px !important;
}

.primary-duc.MuiTypography-root {
  color: #3d4244;
  line-height: 25px;
}

/* client-appointmnet */

.client-appt-check {
  white-space: nowrap;
}

.leave-list-nowrap {
  white-space: nowrap !important;
}

/* client-appointmnet */

/* Pending-Approval css */

.Pending-Appr-container {
  width: 108%;
}

.Pending-Appr-head.MuiTypography-root {
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  margin-top: 10px;
  letter-spacing: 0.8px;
}

.Pending-Appr-btn.MuiButton-root {
  background-color: #15a07d !important;
  color: #ffffff !important;
  height: 50px;
  padding: 18px 16 !important;
  border-radius: 5px !important;
  font-size: 16px;
  text-transform: capitalize !important;
}

/* Pending-Approval css */

.email-btn {
  font-size: 16px;
  font-family: "proxima_novasemibold" !important;
  line-height: 15px;
}

.add-hs-btn {
  width: 136px;
  padding: 10px 0;
  border: 1px solid #15a07d;
  background-color: #15a07d;
  border-radius: 8px;
  color: #fff !important;
  text-align: center;
  font-size: 14px !important;
  font-family: "proxima_novasemibold" !important;
}

.usermang-filter-icon {
  margin-right: -50px !important;
}

.menuBar-icon {
  display: none !important;
  max-width: 20px !important;
  width: 100%;
  position: relative;
  z-index: 2;
  min-width: 42px !important;
}

.menuBar-icon img {
  width: 100%;
}

.main-banner .home-section-button {
  margin-left: 0 !important;
}

.footer-list a span {
  transition: all 0.2s ease-in-out;
}

.footer-list a span:hover {
  color: #15a07d !important;
}

.demo-alert-no-border {
  border: 0;
}

.book-demo-text-end {
  text-align: right !important;
  padding-right: 18px;
}

/* media Query start here  */

@media (max-width: 600px) {
  .book-demo-text-end {
    text-align: center !important;
  }
}

@media (max-width: 1440px) {
  .webContainer {
    margin: auto 80px;
  }

  #webHeader .header-menu-link button {
    padding: 0;
  }

  .homeBanner-main-container {
    /* background-size: 60%; */
    background-size: 56%;
  }
}

@media (max-width: 1320px) {
  #webHeader .header-menu-link {
    margin-right: 26px;
    font-size: 16px;
  }
}

@media (max-width: 1280px) {
  #webHeader .header-menu-link {
    margin-right: 22px;
  }

  .home-content-main h1 {
    font-size: 48px;
  }

  .main-banner .home-section-button {
    width: 150px;
    height: 48px;
  }

  #home-logo-slider {
    padding: 80px 0;
  }

  .homeBanner-main-container {
    padding: 80px 0;
  }

  #webHeader .header-menu-link button,
  #webHeader .header-menu-link {
    font-size: 14px;
  }

  #webHeader .sign-in-button {
    padding: 0 10px;
    height: 36px;
  }

  #webHeader .book-demo-button {
    padding: 0 10px;
    height: 36px;
  }
}

@media (max-width: 991px) {
  #webHeader .sign-in-button {
    margin-left: 15px !important;
  }

  .menuBar-icon {
    display: block !important;
  }

  .webHeader-menubar {
    display: none;
  }

  .card-location-text {
    max-width: 38px;
  }

  .home-content-main p br {
    display: none;
  }

  #webHeader .header-menu-link {
    margin-right: 15px;
  }
}

@media (max-width: 768px) {
  .home-content-main h1 {
    font-size: 32px;
  }

  .marquee {
    width: calc(100% + 64%);
  }
}

@media (max-width: 576px) {
  .webContainer {
    margin: auto 10px;
    padding: 0;
  }

  .menu-drop-text p {
    font-size: 14px;
  }

  .discover-item {
    width: 100% !important;
    margin-bottom: 10px !important;
  }

  .homeBanner-main-container {
    padding: 30px 0;
  }

  .webHeader-logo {
    width: 60px;
    height: 60px;
  }

  .home-content-main p {
    padding: 30px 0;
  }

  .marquee-items .marquee-image {
    margin: 0 10px;
  }

  .webFooter-bg {
    padding: 50px 0;
  }

  .footer-list-wrapper {
    justify-content: flex-start !important;
  }

  .footer-listMobile {
    margin-top: 30px !important;
  }
}

@media only screen and (max-width: 768px) {
  .existing-box-client {
    height: 60vh;
  }
}