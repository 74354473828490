@import url("https://fonts.googleapis.com/css2?family=Inria+Serif:wght@700&display=swap");

:root {
  --p-left-80: 80px;
  --p-right-80: 80px;
  --space-80: 80px 0;
  --space-mt-80: 80px;
  --space-pb-60: 60px;
  --space-pt-60: 60px;
  --space-40: 40px;
  --space-pb-40: 40px;
  --space-pt-40: 40px;
  --space-pl-40: 40px;
  --space-32: 32px 0;
  --space-pt-32: 32px;
  --space-pb-32: 32px;
  --space-24: 24px;
  --space-m-24: 24px 0;
  --space-mt-24: 24px;
  --space-mb-24: 24px;
  --space-pt-80: 80px;
  --space-xl: 160px 0;
  --space-pb-xl: 160px;
  --space-lg: 100px 0;
  --space-mt-lg: 100px;
  --space-120: 120px 0;
  --space-pb-120: 120px;
  /* colors  */

  --success-bg: #15a07d;
  --h-color: #202e2a;

  /* font size  */

  --font-size-18: 18px;
  --h1-size-64: 64px;
  --h-size-36: 36px;
  --f-size-22: 22px;
  --f-size-20: 20px;
}

.text-sm-bold {
  color: #202e2a;
  text-align: center;
  font-family: "proxima_novasemibold" !important;
  font-size: var(--font-size-18) !important;
  line-height: 18px !important;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: var(--h-color);
}

.space-lg {
  padding: var(--space-lg);
}

.space-xl {
  padding: var(--space-xl);
}

.space-m-xl {
  margin: var(--space-xl);
}

.space-pb-xl {
  padding-bottom: var(--space-pb-xl);
}

.pLeft-80 {
  padding-left: var(--p-left-80);
}

.space-m-24 {
  margin: var(--space-m-24);
}

.space-mb-24 {
  margin-bottom: var(--space-mb-24);
}

.space-pb-24 {
  padding-bottom: var(--space-mb-24) !important;
}

.space-mt-24 {
  margin-bottom: var(--space-mt-24);
}

.space-mt-lg {
  margin-top: var(--space-mt-lg);
}

.success-bg {
  background-color: var(--success-bg);
}

.pRight-80 {
  padding-right: var(--p-left-80);
}

.space-pt-80 {
  padding-top: var(--space-pt-80);
}

.space-pb-80 {
  padding-bottom: var(--space-pt-80);
}

.space-120 {
  padding: var(--space-120);
}

.space-pb-120 {
  padding-bottom: var(--space-pb-120);
}

.space-pb-32 {
  padding-bottom: var(--space-pb-32);
}

.space-mt-80 {
  margin-top: var(--space-mt-80);
}

.space-40 {
  padding: 40px 0;
}

.space-80 {
  padding: var(--space-80);
}

.space-m-80 {
  margin: var(--space-80);
}

.space-pb-60 {
  padding-bottom: var(--space-pb-60);
}

.space-pt-60 {
  padding-top: var(--space-pt-60);
}

.space-pb-40 {
  padding-bottom: var(--space-pb-40);
}

.space-pt-40 {
  padding-top: var(--space-pt-40);
}

.space-pl-40 {
  padding-left: var(--space-pl-40);
}

.space-32 {
  padding: var(--space-32);
}

.space-pt-32 {
  padding-top: var(--space-pt-32);
}

.space-mt-24 {
  margin-top: var(--space-mt-24);
}

.space-mb-24 {
  margin-bottom: var(--space-mb-24);
}

.space-pt-24 {
  padding-top: var(--space-mt-24) !important;
}

.text-semi {
  font-family: "proxima_novasemibold" !important;
}

.text-bold {
  font-family: "proxima_novabold" !important;
}

.radious-20 {
  border-radius: 20px;
}

.home-primary-button {
  display: inline-block;
  padding: 12px 40px;
  text-align: center;
  border-radius: 8px;
  color: #fff !important;
  text-decoration: none !important;
  background: #15a07d;
  font-size: var(--font-size-18);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: "proxima_novasemibold" !important;
}

.home-primary-button:hover {
  background: #1bb992;
}

.marquee-items {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.marquee {
  display: flex;
  width: 200%;
  animation: marqueeSlide 20s linear infinite;
}

@keyframes marqueeSlide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

.marquee-logo {
  width: 100px;
}

.marquee-logo img {
  width: 100%;
  object-fit: cover;
}

.overflow-hidden {
  overflow: hidden;
}

#home-logo-slider {
  padding: 72px 0 120px 0;
}

#home-logo-slider p {
  color: #202e2a;
  text-align: center;
  font-size: 24px;
  line-height: normal;
  font-family: "proxima_novabold";
  padding-bottom: 32px;
}

/* home manage cards  */

.manageCards-main {
  padding: 80px 0;
}

.manageCards-main-bg {
  background: rgba(21, 160, 125, 0.05);
}

.manageCarer-cardContent h6 {
  color: #645dd7;
  font-size: 20px;
  font-family: "proxima_novasemibold";
  line-height: normal;
  padding-bottom: 20px;
}

.manageCarer-cardContent h3 {
  color: #202e2a;
  font-size: 36px;
  font-family: "proxima_novasemibold";
  line-height: normal;
}

.manageCarer-cardContent .manageCarer-cardText {
  padding: 40px 0;
}

.manageCarer-cardContent .manageCarer-cardText p {
  color: #4e4e4e;
  font-size: 18px;
  font-family: "proxima_novaregular";
  line-height: normal;
  padding-bottom: 0;
  padding-top: 0;
}

.feature-icons-box {
  margin-top: 0 !important;
}

.feature-icons-box span {
  color: #4e4e4e;
  font-size: 18px;
  line-height: normal;
  font-family: "proxima_novaregular";
  padding-left: 20px;
}

.manageCarer-cardImg {
  height: 450px;
  width: 100%;
}

.manageCarer-cardImg img {
  width: 100%;
  height: 100%;
  /* object-fit: contain !important; */
  border-radius: 20px;
}

.manageCarer-obj img {
  object-fit: contain !important;
}

.space-content {
  padding: 10px;
}

.feature-cards-text {
  padding-left: 20px;
}

#landing-page-features .feature-tickIcon {
  width: 24px;
}

.feature-tickIcon {
  width: 24px;
}

#landing-page-features .owl-theme .owl-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 110%;
}

#landing-page-features .owl-theme .owl-nav .owl-next span,
#landing-page-features .owl-theme .owl-nav .owl-prev span {
  display: none;
}

#landing-page-features .owl-theme .owl-nav .owl-next,
#landing-page-features .owl-theme .owl-nav .owl-prev {
  background-color: #fff !important;
  box-shadow: none;
  outline: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

#landing-page-features .owl-theme .owl-nav .owl-next {
  /* background-image: url(../../assets/images/feature-next-icon.png) !important; */
  background-image: url(../../assets//images/next.png);
}

#landing-page-features .owl-theme .owl-nav .owl-prev {
  /* background-image: url(../../assets/images/feature-prev-icon.png); */
  background-image: url(../../assets/images/left.png);
}

.feature-icons-box h3 {
  color: #109648;
  width: 70px;
}

.manageCard-star-icon {
  min-width: 80px;
}

.manageCard-star-icon img {
  width: 100%;
  object-fit: cover;
}

.feature-icons-box p {
  color: var(--Dark-Text, #202e2a);
  font-size: 24px !important;
  line-height: 22px !important;
  font-family: "proxima_novasemibold" !important;
  padding-left: 20px;
}

.home-transforming-section .manageCards-main-bg {
  padding: 16px;
  border-radius: 8px;
}

.home-transforming-section .manageCards-main-bg p {
  color: #4e4e4e;
  font-size: 18px;
  line-height: normal;
  font-family: "proxima_novaregular";
}

.home-transforming-section .feature-tickIcon {
  margin-right: 16px;
}

.transforming-img {
  width: 100%;
}

.transforming-img img {
  width: 100%;
  object-fit: cover;
}

.transform-img-wrapper {
  border-radius: 20px;
  background: #fff;
  /* border: 1px solid #f1f1f1; */
  box-shadow: 0px 8px 40px rgba(5, 49, 38, 0.12);
  padding: 10px;
}

.home-transforming-section .MuiAccordion-root {
  box-shadow: none;
  border: none;
}

.home-transforming-section .MuiAccordion-root::before {
  display: none;
}

.home-transforming-section .Mui-expanded,
.home-transforming-section .MuiAccordionSummary-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.home-transforming-section .Mui-expanded {
  border-radius: 8px;
  background: linear-gradient(0deg,
      rgba(21, 160, 125, 0.1) 0%,
      rgba(21, 160, 125, 0.1) 100%),
    #fff;
  padding: 3px;
}

.transforming-text {
  padding-left: 40px;
}

.font{
  font-size: 20px !important;
  font-weight: bold !important;
}
.home-tab-wrapper {
  background: #fff;
  border-radius: 12px;
  padding: 8px;
}

.transform-accordion {
  margin-bottom: 20px;
}

.home-transforming-section .MuiAccordionSummary-expandIconWrapper {
  display: none;
}

.accordion-img {
  width: 724px;

  height: 450px;
}

.transforming-none {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-none {
  display: none;
}

.transformingImage1 {
  z-index: 1;
}

.transformingImage2 {
  z-index: 2;
}

.transformingImage3 {
  z-index: 3;
}

.home-transforming-section .Mui-expanded .accordion-img {
  display: block;
}

.accordion-img1 {
  top: -55px;
}

.accordion-img2 {
  top: -162px;
}

.accordion-img3 {
  top: -238px;
}

#homeTab .home-tabs-item {
  padding: 13px 20px;
  color: #4e4e4e;
  text-align: center;
  font-size: 18px;
  font-family: "proxima_novasemibold" !important;
  line-height: 30px;
  /* 166.667% */
}

.home-tab-wrapper .Mui-selected {
  border-radius: 12px;
  background: var(--Primary_Light_10, rgba(21, 160, 125, 0.1));
  color: #15a07d !important;
}

.home-tab-wrapper .MuiTabs-flexContainer {
  justify-content: space-between;
}

.home-tab-videoWrapper {
  border-radius: 20px;
  width: 100%;
  overflow: hidden;
  height: 600px;
  position: relative;
  background: #000;
}

.home-tab-video {
  width: 100%;
  height: 100%;
}

.home-tab-video iframe,
.home-tab-video video {
  width: calc(100% + 20px);
  min-height: 600px;
}

.video-player-button {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: #fff;
  position: relative;
  cursor: pointer;
  border: 1px solid #f1f1f1;
  box-shadow: 0 0 15px #f9f9f9;
  z-index: 2;
}

.video-player-button svg {
  width: 50px;
  height: auto;
}

.slides {
  height: 300px;
  background-color: #15a07d;
}

.tabVideo-tabpennel {
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
}

.home-module-tabMain {
  height: 700px;
}

.tabVideo-tabpennel .MuiBox-root {
  padding-bottom: 0 !important;
  box-shadow: 0px 8px 40px rgba(5, 49, 38, 0.12);
  padding: 0;
  overflow: hidden;
  border-radius: 20px;
}

.home-client-slider {
  background: #f3faf9;
  padding: 80px;
}

.client-homeSlide-content {
  padding: 32px;
  background-color: #fff !important;
}

.client-homeSlide-content .text-box {
  height: 260px;
  overflow: hidden;
  width: 100%;
}

.client-homeSlide-content .text-box p {
  color: #202e2a;
  font-size: 24px;
  line-height: normal;
  font-family: "proxima_novasemibold" !important;
}

.home-client-slider .carousel-container .owl-theme {
  height: auto;
}

.home-client-slider .carousel-container .owl-item::after {
  background: #fff;
}

.home-client-slider .owl-item {
  background: none;
}

.home-client-slider .owl-item .item {
  border-radius: 12px;
  border: 1px solid var(--stroke-border, #e3e8e6);
  background: #fff;
  overflow: hidden;
}

.home-client-slider .owl-nav {
  position: absolute;
  top: -136px;
  right: 0;
}

.home-client-slider .owl-carousel .owl-nav .owl-prev,
.home-client-slider .owl-carousel .owl-nav .owl-next {
  color: #f3faf9;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid #bababa;
  margin-left: 16px;
  background-color: #f3faf9;
}

.home-client-slider .owl-carousel .owl-nav .owl-prev span,
.home-client-slider .owl-carousel .owl-nav .owl-next span {
  display: none;
}

.home-client-slider .owl-carousel .owl-nav .owl-prev {
  background-image: url(../../assets/images/client-arrow-left.png);
}

.home-client-slider .owl-carousel .owl-nav .owl-next {
  background-image: url(../../assets/images/client-arrow-right.png);
}

.client-slide-names p {
  font-size: 20px;
  line-height: normal;
  font-family: "proxima_novabold";
}

.client-slide-names span {
  color: #202e2a;
  font-size: 20px;
  line-height: normal;
  font-family: "proxima_novaregular";
}

.home-client-slider .owl-dots {
  display: none;
}

.home-client-slider .owl-nav {
  display: block !important;
}

.blank-box {
  height: 100px;
  background-color: #fff;
  width: 100%;
}

.last-box {
  height: 108px;
  background-color: #fff;
  width: 100%;
}

.discover-item {
  margin: auto;
  cursor: pointer;
}

.srvc_card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 380px;
  height: 340px;
  overflow: hidden;
  flex-direction: column;
  color: #fff;
  /* border-radius: 10px; */
}

.srvc_card a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border: solid 1px #ddd;
  background: #fff;
  color: inherit;
  overflow: hidden;
  border-radius: 12px;
  text-decoration: none !important;
}

.srvc_card .srvc_animtext {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /*overflow: hidden;*/
  white-space: nowrap;
  font-size: 250px;
  line-height: 0.92;
  padding-top: 15px;
  padding-left: 82px;
}

.srvc_card .srvc_animtext .animtext {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: inherit;
  background: inherit;
  background-repeat: repeat-x;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke: 1px #ddd;
  padding-right: 0.26em;
}

.srvc_card .srvc_animtext .animtext span {
  display: inline-block;
  transition: all ease-in-out 2s;
}

.srvc_card .srvc_animtext .animtext span:after {
  content: attr(title);
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
}

.srvc_card .srvc_title {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 18px 30px 40px 35px;
  align-items: flex-end;
  margin-top: auto;
}

.srvc_card .srvc_title span {
  display: block;
  padding-right: 15px;
  font-size: 24px;
  color: #000;
  line-height: 34px;
}

.srvc_card .srvc_title .arrow {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  min-width: 30px;
  width: 30px;
  height: 30px;
  background: #15a07d;
  border-radius: 100%;
  justify-content: center;
  margin-left: auto;
}

.srvc_card .srvc_title .arrow svg {
  width: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.srvc_card .srvc_title .arrow svg path {
  fill: #fff;
}

.srvc_card a,
.srvc_card .srvc_animtext .animtext,
.srvc_card .srvc_title span,
.srvc_card .srvc_title .arrow,
.srvc_card .srvc_title .arrow svg path {
  -webkit-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
}

.srvc_card:hover .srvc_animtext .animtext {
  -webkit-animation-name: slide;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-play-state: running;
  animation-name: slide;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 24s;
}

.srvc_card:hover a {
  background: #15a07d;
  border-color: #15a07d;
}

.srvc_card:hover .srvc_animtext .animtext {
  -webkit-text-stroke: 1px #fff;
}

.srvc_card:hover .srvc_title span {
  color: #fff;
}

.srvc_card:hover .srvc_title .arrow {
  background: #fff;
}

.srvc_card:hover .srvc_title .arrow svg path {
  fill: #15a07d;
}

@-webkit-keyframes slide {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slide {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

/* book demo css  */

.bookDemo-main {
  background-color: #645dd7;
  border-radius: 40px;
}

.bookDemo-main h1 {
  color: #fff;
  padding-top: 20px;
}

.bookDemo-main .text,
.bookDemo-main li {
  color: #ededed;
  font-size: 18px;
  font-family: "proxima_novaregular";
  line-height: normal;
}

.bookDemo-main .text-semi {
  font-size: 20px;
  color: #fff;
}

.bookDemo-btn {
  background: linear-gradient(0deg,
      rgba(100, 93, 215, 0.1) 0%,
      rgba(100, 93, 215, 0.1) 100%),
    #fff;
  display: inline-block;
  color: #645dd7 !important;
  border: 1px solid #fff;
  font-family: "proxima_novasemibold" !important;
  font-size: 18px;
}

.bookDemo-btn svg {
  margin-left: 10px;
}

.bookDemo-btn:hover {
  background-color: #645dd7;
  border-color: #fff;
  color: #fff !important;
}

.bookDemo-main li {
  margin: 0 20px 0 12px;
}

.bookDemo-main li {
  list-style: disc;
}

.bookDemo-main li:nth-child(1) {
  list-style: none;
}

.bookDemo-main ul {
  padding-top: 20px;
}

.footer-logo {
  width: 90px;
  margin-bottom: 32px;
}

.footer-logo img {
  width: 100%;
  object-fit: cover;
}

.webFooter-bg p {
  color: var(--Dark-Text, #202e2a);
  font-size: 20px;
  font-family: "proxima_novaregular";
  line-height: normal;
}

.webfooter-icons svg {
  width: 22px;
  margin-right: 22px;
  transition: all 0.2s ease-in-out;
}

.webfooter-icons svg:hover path {
  fill: #15a07d !important;
}

.footer-list-wrapper p {
  color: var(--Dark-Text, #202e2a);
  font-size: 18px;
  line-height: 46px;
  /* 255.556% */
  letter-spacing: 0.225px;
  font-family: "proxima_novabold";
}

.footer-list-wrapper .footer-list li a {
  padding: 0;
  display: inline-block;
  background: none !important;
  color: #202e2a !important;
}

.footer-list-wrapper .footer-list li {
  width: auto !important;
}

.footer-listWrapBox {
  width: 146px;
}

.copyright-text {
  color: var(--Dark-Text, #202e2a);
  text-align: center;
  font-family: "proxima_novaregular";
  font-size: 16px;
  line-height: 29.979px;
  /* 187.37% */
  letter-spacing: 0.32px;
}

/* .transforming-text {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
} */

.home-tab-videoWrapper::after {
  /* content: ""; */
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(13, 13, 14, 0.1);
  z-index: 1;
}

.bookDemo-wrapper {
  width: 90%;
  margin: auto;
}

/* feature page css  */

.feature-titleBox {
  padding: 120px 0;
  overflow: hidden;
}

.feature-star-icon {
  width: 48px;
  min-width: 48px;
}

.feature-star-icon img {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.featureBannerImg {
  position: relative;
}

.featureBannerImg::after {
  content: "";
  position: absolute;
  top: 30px;
  left: -113px;
  z-index: 3;
  background-image: url(../../assets/images/feature-1attachement.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 226px;
  height: 226px;
  background-size: cover;
}

.featureBannerImg .manageCarer-cardImg {
  position: relative;
  z-index: 2;
}

.question-accordian .MuiAccordion-root {
  box-shadow: none !important;
}

.question-accordian .MuiAccordion-root::before {
  display: none;
}

/* web banner bg blur  */

.blurBg {
  filter: blur(41.77207565307617px);
  border-radius: 50%;
  position: absolute;
  z-index: 1;
}

.blurYellow {
  width: 260px;
  height: 260px;
  background: rgba(253, 212, 67, 0.34);
  bottom: -69px;
  left: -86px;
}

.blurRed {
  width: 121px;
  height: 121px;
  background: rgba(252, 122, 87, 0.2);
  top: -38px;
  left: 0;
}

.blurPurple {
  width: 404px;
  height: 404px;
  background: rgba(100, 93, 215, 0.15);
  right: 0px;
  top: -33px;
}

/* blogs page css start here  */

.share-button-linkedin {
  background-color: #0a66c2 !important;
  height: 50px;
  width: 50px;
  border-radius: 100%;
}

.share-button-facebook {
  background-color: #0866ff !important;
  height: 50px;
  width: 50px;
  border-radius: 100%;
}

.share-button-x {
  background-color: black !important;
  height: 50px;
  width: 50px;
  border-radius: 100%;
}

.blog-main-banner {
  position: relative;
  overflow: hidden;
}

.blog-main-banner .blurYellow {
  bottom: -174px;
  left: 399px;
}

.blog-main-banner .blurRed {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.experience_filter {
  display: flex;
  gap: 6px;
  margin: 10px 0;
}

.experience_filter button {
  cursor: pointer;
  border: 1px solid;
  border-radius: 4px;
  padding: 4px;
  background-color: white;
}

.experience_filter button.selected {
  background-color: wheat;
}

.blog-cardImg {
  height: 300px;
}

.blog-cardImg img {
  width: 100%;
  border-radius: 20px;
  height: 100%;
  box-shadow: 0px 5px 12px 0px rgba(1, 46, 70, 0.1);
}

.blog-card-smTitle {
  margin-top: 24px;
  display: inline-block;
  height: 24px;
  padding: 0 8px;
  border-radius: 4px;
  background: rgba(21, 160, 125, 0.1);
}

.blog-card-smTitle span {
  color: var(--Dark-Text, #202e2a);
  font-size: 13px;
  display: inline-block;
  font-weight: 400;
  line-height: normal;
  font-family: "proxima_novaregular";
}

.blog-card-title {
  margin: 16px 0;
  width: 100%;
}

.blog-card-title h3 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.question-title {
  font-family: "proxima_novabold";
  font-size: 18px;
  color: #202e2a;
}

.home-tab-wrapper .MuiTabScrollButton-horizontal {
  display: none !important;
}

.blogCard-text p {
  color: #4e4e4e;
  /* Body Text H6 18 Regular */
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  font-family: "proxima_novaregular";

  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

#blogFilter .blog-filter-button button {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #f6f6f6;
  color: var(--Dark-Text, #202e2a);
  margin: 0 4px;

  /* Body Text H6 18 Regular */

  font-size: 18px;
  font-family: "proxima_novaregular";
  line-height: normal;
}

#blogFilter .blog-filter-activeBtn {
  background: #15a07d !important;
  color: #fff !important;
}

.web-pagination .MuiPaginationItem-page {
  border: 1px solid #15a07d;
  background: none;
  color: #15a07d;
  border-radius: 4px;
}

.web-pagination .MuiPaginationItem-page.Mui-selected {
  background: #15a07d !important;
  color: #fff !important;
}

#guides-details .guide-details-Content h1 {
  font-size: var(--h1-size-64);
}

#guides-details .guide-details-Content p {
  font-size: var(--f-size-20);
  font-family: "proxima_novasemibold";
}

.banner-text-md {
  color: #4e4e4e;
  text-align: center;
  font-size: 20px;
  font-family: "proxima_novasemibold";
  line-height: normal;
}

#guides-details .guide-details-Content span {
  color: #8c8c8c;
  text-align: center;
  font-size: 14px;
  font-family: "proxima_novaregular";
  line-height: normal;
}

.guides-details-text {
  width: calc(100% - 360px);
  margin: auto;
}

.guides-details-text p {
  color: #4e4e4e;
  font-size: var(--f-size-22) !important;
  font-family: "proxima_novaregular";
  line-height: 150%;
  /* 33px */
}

.guides-details-points li {
  padding-top: 24px;
}

.guides-details-points .point-green {
  margin-right: 10px;
}

.guides-details-points .point-green path,
.guides-details-points .point-green circle {
  fill: #15a07d;
}

.free-guide-info {
  padding: 65px 50px 0 50px;
  /* padding: 32px 50px 0 50px; */
  background-color: #15a07d;
}

.free-guide-info p {
  color: #fff;
  font-size: 20px;
  padding-bottom: 6px;
  line-height: normal;
  font-family: "Inria Serif", serif;
  font-weight: 700;
}

.free-guide-info span {
  color: #fff;
  font-size: 15px;
  line-height: normal;
  font-family: "proxima_novasemibold";
  padding-bottom: 32px;
}

.guide-info-image {
  width: 100%;
  /* transform: translateY(16px); */
  transform: translateY(10px);
}

.guide-download-login p {
  padding-top: 10px;
}

.guide-info-image img {
  width: 100%;
}

.guide-download-login {
  padding: 89px 77px;
  background-color: #f6f6f6;
}

#guides-details .guide-email-input {
  width: 300px;
  border-radius: 8px;
  background: #fff;
  margin-right: 10px;
}

.guide-email-input fieldset {
  border: none;
  outline: none;
}

.guide-download-login .web-primary-btnLarge {
  background-color: #15a07d;
}

.home-section-button {
  background-color: #15a07d;
  border: none;
  margin-left: 0 !important;
}

.contact-form .home-section-button {
  margin-left: 0;
}

.contact-form .form-input {
  margin-top: 0;
  margin-bottom: 24px;
}

.contact-form .form-input input::placeholder {
  color: #8c8c8c;
}

.guide-download-linkIcons {
  width: 40px;
  margin: 0 10px;
}

.guide-download-linkIcons img {
  width: 100%;
}

.guide-sm-divider {
  width: 120px;
  height: 2px;
  background: #e3e8e6;
  margin: auto;
}

.guides-like-main {
  padding-left: 80px;
  padding-right: 80px;
}

.guides-like-main h1 {
  line-height: 43px;
}

/* contact page css  */

.contact-address-box {
  border-radius: 20px;
  background: #15a07d;
  box-shadow: 0px 8px 40px 0px rgba(5, 49, 38, 0.12);
  padding: 56px 24px;
  transform: translateY(-150px);
  position: relative;
  z-index: 2;
}

.contact-address-box p,
.contact-address-box h6,
.contact-address-box a {
  color: #fff;
}

.space-pb-200 {
  padding-bottom: 200px !important;
}

.contact-address-box p {
  color: #aceede;
  text-align: center;
  font-size: var(--font-size-18);
  line-height: normal;
  font-family: "proxima_novaregular";
  padding-bottom: 17px;
  padding-top: 8px;
}

.contact-address-box h6 {
  font-size: var(--font-size-18);
  line-height: normal;
  letter-spacing: 0.25px;
  font-family: "proxima_novasemibold";
}

.contact-address-box a,
.contact-address-box h5 {
  font-size: var(--font-size-18);
  line-height: normal;
  font-family: "proxima_novasemibold";
  letter-spacing: 0.225px;
  color: #fff;
}

.contact-address-box a {
  text-decoration-line: none;
  line-height: 17px;
  border-bottom: 1px solid #fff;
}

.team-linkdin-icon {
  position: absolute;
  bottom: 32px;
  right: 25px;
  width: 40px;
  z-index: 1;
}

.address-icons {
  width: 48px;
  margin-bottom: 20px;
  margin: auto;
}

.address-icons img {
  width: 100%;
}

.guides-details-text h6 {
  color: #202e2a;
  font-size: 22px;
  line-height: 150%;
  /* 33px */
  font-family: "proxima_novabold";
  padding: 32px 0;
}

#Contact .blog-main-banner .blurYellow {
  bottom: -30px;
}

.contact-form h2 {
  font-size: var(--h-size-36);
}

.contact-form p {
  color: #8c8c8c;
  font-size: var(--font-size-18);
  line-height: normal;
  font-family: "proxima_novasemibold";
}

.contact-input-fields fieldset {
  border-radius: 8px;
  border: 1px solid var(--stroke-border, #e3e8e6);
  outline: none !important;
}

.contact-input-fields div:hover,
.contact-input-fields div:focus {
  border: none !important;
  outline: none;
}

.contact-input-fields input {
  outline: none !important;
}

.contact-input-fields input::placeholder {
  color: #8c8c8c !important;
}

.about-hubshift-img {
  transform: translateY(-150px);
  position: relative;
  z-index: 2;
}

#about .blog-main-banner .blurYellow {
  left: 0;
  bottom: 0;
}

#about .blog-main-banner .blurRed {
  left: 150px;
  top: 40%;
  transform: translateY(-40%);
}

#about .blurPurple {
  top: auto;
  bottom: 0;
}

.about-description p {
  padding-bottom: 15px;
  color: #4e4e4e;
  font-family: "proxima_novaregular";
  font-size: var(--font-size-18);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.different-card-title h1 {
  color: #15a07d;
  line-height: normal;
  font-family: "proxima_novabold";
}

.different-card-title h3 {
  font-family: "proxima_novasemibold";
  padding: 20px 0;
}

.differentCard-text p {
  color: #4e4e4e;
  font-size: var(--font-size-18);
  line-height: normal;
  font-family: "proxima_novaregular";
}

.about-full-divider {
  width: 100%;
  background: #e3e8e6;
  height: 1px;
  margin: 40px 0;
}

/* about  missions css   */

.about-missions-content p {
  color: #4e4e4e;
  font-size: var(--font-size-18);
  font-family: "proxima_novaregular";
  line-height: 32px;
  /* 177.778% */
}

.about-missions-content-row {
  padding-bottom: 16px;
}

.about-missions h1 span {
  color: #15a07d;
}

.about-team-card {
  padding-top: 40px !important;
}

.about-team-section {
  background: rgba(21, 160, 125, 0.05);
}

.about-team-content p {
  color: #4e4e4e;
  font-size: var(--f-size-20);
  line-height: normal;
  font-family: "proxima_novaregular";
}

.about-team-content h3 {
  font-family: "proxima_novasemibold";
}

/* help-center page  css here   */

.helpTab-left-panel {
  width: 310px;
}

.helpTab-panel {
  width: calc(100% - 312px);
  padding-left: 80px;
}

.helpTab-panel>div {
  padding: 0;
}

.help-details-wrapper {
  max-width: 804px;
  width: 100%;
  margin: auto;
}

#help-center .helpTabs .MuiTab-textColorPrimary {
  align-items: flex-start !important;
  text-transform: capitalize !important;
  color: var(--Dark-Text, #202e2a);
  font-size: var(--f-size-20);
  line-height: normal;
  font-family: "proxima_novasemibold";
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#help-center .helpTabs .Mui-selected {
  color: #15a07d;
}

.help-tabs-content {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid var(--stroke-border, #e3e8e6);
}

.help-tabs-content ul li {
  padding: 16px;
}

.help-tabs-content h6 {
  color: #202e2a;
  font-size: var(--f-size-20);
  line-height: normal;
  font-family: "proxima_novasemibold";
  padding: 16px 0 0 16px;
}

#help-center .about-full-divider {
  margin: 16px 0 !important;
}

.help-tabs-content ul li a {
  color: #4e4e4e;
  font-size: var(--font-size-18);
  line-height: normal;

  font-family: "proxima_novaregular";
}

.help-tabs-content ul li {
  border-radius: 8px;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
}

.help-tabs-content ul li:hover {
  background: rgba(21, 160, 125, 0.1);
}

.help-tabs-content img {
  width: 80%;
}

/* help center details page css   */

.help-center-details h2 {
  font-size: var(--h-size-36);
  padding-top: 24px;
}

.help-center-details p {
  color: #4e4e4e;
  font-size: var(--f-size-22);
  line-height: normal;
  font-family: "proxima_novaregular";
  padding-bottom: 32px;
}

.help-center-details h3 {
  color: #202e2a;
  font-size: var(--f-size-22);
  font-family: "proxima_novabold";
  line-height: 150%;
  /* 33px */
  padding-bottom: 20px;
}

.help-center-details .help-imageDetails {
  padding-top: 20px;
}

.pricing-pointss {
  flex-grow: 1;
  overflow-y: auto;
}

.flexWrap{
  display: flex;
  flexWrap: wrap;
}

.text-center {
  margin-top: auto;
}


#PricingMain .pricing-card {
  padding: 48px 32px 32px 32px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 5px 12px 0px rgba(5, 49, 38, 0.1);
  overflow: hidden;
}

.height{
  height: 100% !important;
}

/* pricing page css   */

#PricingMain .home-tab-wrapper {
  width: 488px;
  margin: auto;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 5px 12px 0px rgba(5, 49, 38, 0.1);
}

#PricingMain .home-tab-wrapper button {
  padding-left: 40px;
  padding-right: 40px;
}

#PricingMain .home-tab-wrapper .Mui-selected {
  background: #15a07d;
  color: #fff !important;
}

.pricing-card p {
  color: #202e2a;
  font-size: var(--f-size-20);
  font-family: "proxima_novasemibold";
  line-height: 175%;
  /* 35px */
}

.pricing-points ul li {
  display: flex;
  align-items: center;
  padding-bottom: 14px;
}

.pricing-points {
  /* height: 370px; */
  height: 382px;
}

#PricingMain .pricing-card-btn {
  display: flex;
  width: 100%;
  height: 56px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: var(--Primary_Light_10, rgba(21, 160, 125, 0.1));
  color: #15a07d;
  font-size: var(--font-size-18);
  font-family: "proxima_novasemibold";
  line-height: normal;
  transition: all 0.2s ease-in-out;
}

#PricingMain .pricing-card-btn:hover {
  background: #15a07d;
  color: #fff;
}

.pricing-points ul li img {
  width: 20px;
  margin-right: 12px;
}

.pricing-card span {
  color: #8c8c8c;
  font-size: var(--f-size-20);
  line-height: 175%;
  /* 35px */
  font-family: "proxima_novaregular";
  padding-left: 12px;
}

.standard-chip {
  position: absolute;
  top: 0;
  right: -27px;
  z-index: 1;
  height: 100px;
  width: 133px;
}

.standard-chip span {
  position: absolute;
  top: 9px;
  color: #fff;
  color: #fff;
  text-align: center;
  width: 52px;
  font-size: 16px;
  font-family: "proxima_novasemibold";
  line-height: 109.5%;
  transform: rotate(45deg);
  right: 40px;
}

.compare-features h2 {
  font-size: var(--h-size-36);
}

.table-single-row {
  background: #f6f6f6;
  padding: 17px 32px;
  color: #202e2a;
  font-family: "proxima_novasemibold";
  font-size: var(--font-size-18);

  line-height: normal;
}

.pricing-table td {
  padding-top: 17px;
  padding-bottom: 17px;
}

.pricing-table img {
  width: 24px;
}

.pricing-table th,
.pricing-table td,
.pricing-table,
.pricing-table tbody,
.pricing-table tr {
  border: none !important;
  box-shadow: none !important;
}

.pricing-table thead th {
  color: #202e2a;
  font-size: 24px;
  line-height: normal;
  font-family: "proxima_novasemibold";
}

#PricingMain .table-single-row {
  color: #202e2a;
  font-family: "proxima_novasemibold";
  font-size: var(--font-size-18);
  line-height: normal;
}

.landing-banner {
  background-image: url(../../assets/images/landing-banner1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.landing-form {
  background-color: #fff;
  padding: 40px 42px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  max-width: 450px;
  width: 100%;
}

.landing-form-wrapper {
  transform: translateY(75px);
}

.accordion-main-wrapper {
  width: 80%;
  margin: auto;
}

.landing-form-inputsFields input {
  padding: 15px;
  border-radius: 8px;
  background: rgba(227, 232, 230, 0.5);
}

.landing-form-inputsFields input::placeholder {
  color: #a8a8a8 !important;
}

.landing-form-inputsFields .MuiSelect-select {
  padding: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background: rgba(227, 232, 230, 0.5);
}

.landing-form .landing {
  border-radius: 8px;
}

.landing-form .MuiFormControl-root fieldset {
  border: none !important;
}

.menu-option-default {
  color: #cdc7c7 !important;
  font-size: 16px;
  font-style: normal;
  padding-top: 0 !important;
  font-family: "proxima_novasemibold" !important;
  line-height: 14px !important;
  /* 87.5% */
  letter-spacing: 0.16px;
  padding-bottom: 0 !important;
}

.landing-form-inputsFields .MuiInput-underline::after,
.landing-form-inputsFields .MuiInput-underline::before {
  display: none;
}

.landing-form p {
  padding: 16px 0 5px 0;
  color: #202e2a;
  font-size: 16px;
  font-family: "proxima_novaregular";
  line-height: 175%;
  /* 28px */
}

.landing-banner h1 {
  color: #fff;
  font-size: 56px;
}

#landingpage .web-header {
  padding: 30px 0;
}

#landingpage .header-sticky {
  padding: 10px 0 !important;
}

#landingpage .landing-form-autoComplete fieldset {
  border-radius: 8px;
  border: none;
  background: rgba(227, 232, 230, 0.5);
}

#landingpage .landing-form-autoComplete input {
  background: none !important;
  color: #bdbcbc;
  font-family: "proxima_novasemibold";
  padding: 16px 13px;
}

.cutom-field input {
  color: #000000 !important;
}

.landing-form-autoComplete .MuiAutocomplete-input::placeholder {
  color: red !important;
}

#landingpage .MuiInputBase-formControl {
  padding: 0;
}

.web-menu-drawwer a,
.web-menu-drawwer button {
  display: block;
  color: #202e2a;
  font-size: var(--font-size-18);
  line-height: 175%;
  /* 31.5px */
  font-family: "proxima_novasemibold";
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-transform: capitalize !important;
}

.web-menu-drawwer button {
  display: flex;
  align-items: center;
}

.web-menu-drawwer {
  padding: 20px;
}

.web-menu-drawwer .header-menu-link {
  margin-bottom: 30px;
}

.web-menu-drawwer .header-menu-custom {
  margin-top: 0;
}

.web-menu-drawwer .header-menu-custom2 {
  margin-bottom: 0;
  margin-top: 0;
}

.web-menu-drawwer .header-menu-custom3 {
  margin-bottom: 0;
  margin-top: 0;
}

.web-menu-drawwer .header-menu-custom4 {
  margin-bottom: 0;
  margin-top: 0;
}

.web-menu-drawwer .book-demo-button {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

#PricingTab .MuiTabs-indicator {
  display: none;
}

/* book demo css  */

.book-modalMain .MuiDialog-container .MuiDialog-paperFullWidth {
  max-width: 420px;
  width: 100%;
  border-radius: 12px;
}

.modal-content-wrapper {
  padding: 0 !important;
}

.modal-content-wrapper .modal-content p {
  padding: 20px 24px !important;
}

.bookDemo-modalTitle {
  background: rgba(21, 160, 125, 0.1);
}

.bookDemo-modalTitle p {
  color: #202e2a;
  font-size: 20px;
  font-family: "proxima_novasemibold";
  line-height: normal;
}

.demoTimeSlot .selected-date {
  color: #202e2a;
  font-size: 16px;
  font-family: "proxima_novasemibold";
  line-height: normal;
}

.demoTimeSlot .duration {
  color: #000;
  text-align: right;
  font-size: 16px;
  font-family: "proxima_novaregular";
  line-height: normal;
}

.book-demo-calender {
  width: 60%;
  margin: auto;
}

.bookDemo-bg {
  background-color: #f7f7f7;
}

#BookDemo .slide-filed {
  max-width: 166px;
  min-width: none;
}

#BookDemo .fc-scrollgrid-sync-table {
  width: 750px !important;
}

.bookdemo-calender-wrapper {
  margin-bottom: 200px;
}

#BookDemo .fc-h-event {
  background-color: transparent !important;
  border: none !important;
}

#BookDemo .fc-h-event button {
  color: #15a07d !important;
}

#bookDemo-calender-data {
  text-align: center;
}

.bookDemo-appointment {
  width: 100%;
  height: 100%;
}

.bookDemo-appointment-modal {
  background: #fff;
  max-width: 600px;
  width: 100%;
  padding: 40px;
  border-radius: 10px;
}

#bookDemo-calender-data .Mui-selected {
  background-color: #fff !important;
  color: #fff !important;
}

#bookDemo-calender-data .MuiPickersDay-today {
  background-color: #fff !important;
}

.bookDemo-appointment-modal input {
  border-radius: 8px;
  border: 1px solid var(--stroke-border, #e3e8e6);
  padding: 8px;
  margin-top: 10px;
}

.bookDemo-appointment-modal .MuiInput-underline::after,
.bookDemo-appointment-modal .MuiInput-underline::before {
  display: none;
}

.bookDemo-appointment-modal .web-primary-btnLarge {
  margin-left: 0;
  margin-top: 16px;
  background-color: #15a07d;
}

#BookDemo .fc-header-toolbar {
  display: none;
}

.landing-form-inputsFields .default-menu,
#landingpage input::placeholder {
  color: #a8a8a8 !important;
  font-size: 16px;
  line-height: 14px;
  /* 87.5% */
  letter-spacing: 0.16px;
  font-family: "proxima_novasemibold";
}

.landing-form-inputsFields .default-menu {
  margin-top: 4px;
}

.home-logo-slider {
  margin-top: 50px;
}

.bookDemo-main p {
  max-width: 757px;
  width: 100%;
  margin: auto;
}

.modal-slotTime {
  display: flex;
  width: 100%;
  padding: 12px 0;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 0 !important;
  border: 1px solid #e3e8e6;
}

.modal-slotTime span {
  color: #202e2a;
  font-size: 16px;
  line-height: normal;
  font-family: "proxima_novaregular";
}

.modal-slotTime-disable {
  background: #e3e8e6 !important;
}

.modal-slotTime-disable span {
  color: #8c8c8c !important;
}

.bookSlot-success-bg {
  background: rgba(21, 160, 125, 0.1) !important;
}

.bookSlot-success-bg span {
  color: #15a07d !important;
}

#demo-request .form-field {
  margin-bottom: 24px;
  margin-top: 0 !important;
}

#demo-request .form-input {
  margin-bottom: 0 !important;
}

#demo-request .bookDemo-submit {
  padding: 14px 90px;
  height: auto;
}

#demo-request .fc-daygrid-day-events {
  height: 20px !important;
  position: relative;
  top: 2px;
  right: -33px;
  width: 28px !important;
}

#demo-request .fc-theme-standard th {
  border: none !important;
  border-bottom: none !important;
}

#demo-request .fc-scrollgrid {
  border: none !important;
}

#demo-request .demo-request-wrapper {
  background-color: #fff;
  padding: 40px 80px;
  border-radius: 12px;
}

#demo-request .fc .fc-scrollgrid table {
  width: 560px !important;
}

#demo-request .fc-theme-standard td {
  border: none !important;
  border-bottom: none !important;
}

#demo-request .fc .fc-daygrid-day-top {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#demo-request .fc .fc-daygrid-day-frame {
  /* width: 60px; */
  /* height: 60px; */
  border-radius: 50%;
  min-height: auto !important;
  transition: all 0.2s ease-in-out;
  width: 42px;
  height: 42px;
}

#demo-request .fc .fc-daygrid-day-frame:hover {
  background-color: #15a07d;
}

#demo-request .fc .fc-daygrid-day-frame:hover a,
#demo-request .fc .fc-daygrid-day-frame:hover svg {
  fill: #fff !important;
  color: #fff !important;
}

#demo-request .fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
  background: none !important;
}

#demo-request .fc-header-toolbar {
  display: flex !important;
  width: 85%;
  margin-left: 54px;
}

#demo-request .fc .fc-toolbar-title {
  font-size: 20px !important;
  margin-left: -37px;
}

#demo-request .fc .fc-button-primary {
  border: none !important;
  background-color: #fff !important;
  outline: none !important;
  box-shadow: none !important;
}

#demo-request .fc .fc-button-primary span {
  color: #202e2a;
}

#demo-request .fc .fc-daygrid-body {
  display: flex;
  justify-content: center !important;
}

#demo-request .fc .fc-scroller {
  display: flex;
  justify-content: center !important;
}

#demo-request .fc .fc-daygrid-day.fc-day-today {
  background: none;
}

#demo-request .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-frame {
  border: 1px solid #15a07d !important;
}

.bookDemo-calender-main {
  margin-top: 6px;
}

.bookDemo-calender-main .demo-app-main {
  border: 1px solid #e3e8e6;
  border-radius: 12px;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 32px;
}

.my-calendar-container {
  max-width: 400px;
  /* margin: auto; */
  border: 1px solid #e3e8e6;
  border-radius: 12px;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 25px;
}

.calender-tile .fc-toolbar-title {
  font-size: 14px;
}

.calender-button .fc-button {
  font-size: 12px;
  padding: 4px 6px;
}

.demo-step-section p {
  font-size: var(--f-size-20);
  color: #202e2a;
  font-family: "proxima_novasemibold";
}

.demo-step-section span {
  color: #8c8c8c;
  font-size: 14px;
  font-family: "proxima_novaregular";
  padding-bottom: 10px;
}

#demo-request .form-main-box {
  /* padding-top: 32px; */
  padding-top: 10px;
}

.watch-icon path {
  fill: #15a07d;
}

.watch-icon {
  margin-right: 6px !important;
}

#demo-request {
  /* padding: 100px 0; */
  padding: 100px 0 385px;
}

#demo-request .fc-daygrid-day-number {
  color: #202e2a;
}

.book-demo-webContainer {
  max-width: 1000px;
  margin: auto;
}

.bookCalender-margin {
  /* padding-top: 32px; */
  padding-top: 10px;
}

.bookDemo-bg .web-header {
  background: #fff !important;
}

/* .modal-slotTime .MuiFormControlLabel-label{ 
  position: relative;
  z-index: 2;
}  */

.salmon {
  width: 200px;
  height: 100px;
  color: #000;
}

.bg-salmon {
  background-color: #15a07d;
}

.modal-slot-main {
  padding: 19px 24px;
  gap: 10px;
  /* padding:  32px 24px ; */
  /* gap: 20px; */
}

.modal-slot-wrapper {
  width: 110px;
}

.demoTimeSlot .agency-btn-section {
  padding: 0 24px;
  margin-top: 0;
}

.demoTimeSlot a {
  margin-right: 0 !important;
  background-color: #15a07d;
  color: #fff !important;
}

.bookSlot-select-wrapper {
  background-color: #affed080;
  padding: 11px 20px 11px 13px;
  border-radius: 4px;
}

.bookSlot-select-wrapper1 {
  background-color: #affed080 !important;
  padding: 11px 20px 11px 13px;
  border-radius: 4px !important;
  /* max-width: fit-content !important; */
}

.book-slot-datepicker .MuiInputBase-formControl {
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

.book-slot-datepicker {
  margin-top: 0 !important;
}

.book-slot-datepicker .MuiInputBase-formControl input {
  padding-top: 0;
  padding-bottom: 0;
  width: 100px;
  padding-left: 10px;
}

.book-slot-datepicker .MuiInputBase-formControl fieldset {
  border: none;
}

.bookSlot-divider {
  background-color: #92e7b5;
  width: 1px;
  height: 24px;
}

.book-timeSlot {
  margin-left: 22px;
}

.book-timeSlot input {
  background: none;
  border: none;
  outline: none;
  font-size: 16px;
  font-family: "proxima_novaregular";
  display: flex;
  flex-direction: row-reverse;
}

.Book-select-slot p {
  font-size: 14px;
  font-family: "proxima_novaregular";
  color: #202e2a;
  white-space: nowrap;
}

#getSupport .featureBannerImg::after {
  display: none;
}

#getSupport .feature-titleBox {
  padding: 80px 0;
}

.addTask-timeSlot input {
  width: 100% !important;
}

.webContainer img {
  max-height: 600px;
  object-fit: cover;
}

@media (max-width: 1440px) {
  .home-client-slider .owl-nav {
    top: -105px;
  }
}

@media (max-width: 1366px) {
  :root {
    --p-left-80: 60px;
    --p-right-80: 60px;
    --space-80: 60px 0;
    --space-pb-60: 40px;
    --space-pt-60: 40px;
    --space-pb-40: 40px;
    --space-pt-40: 40px;
    --space-pl-40: 40px;
    --space-pt-80: 60px;
    --space-xl: 100px 0;
    --space-pb-xl: 100px;

    --space-32: 32px 0;
    --space-pt-32: 32px;
    --space-24: 24px;
    --space-m-24: 24px;
    --space-mt-24: 24px;
    --space-mb-24: 24px;
    /* colors  */

    --success-bg: #15a07d;
    --h-color: #202e2a;

    /* font size  */

    --font-size-18: 18px;
    --h1-size-64: 64px;
    --h-size-36: 36px;
    --f-size-22: 22px;
  }

  .home-client-slider .owl-carousel {
    height: 390px;
  }

  .main-banner .owl-theme .owl-nav {
    left: 0;
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -56px;
  }

  .home-client-slider .owl-stage-outer,
  .home-client-slider .owl-stage {
    height: 100%;
  }

  .accordion-img {
    left: calc(100% + 13%);
  }

  /* 

  .accordion-img1 { 
    top: -51px;
  }
  
  .accordion-img2 { 
    top: -80%;
   }

   .accordion-img3 { 
    top: calc( -100% + -36px );
   } */
  .accordion-img2 {
    top: -132px;
  }

  .accordion-img3 {
    top: -206px;
  }

  .srvc_card {
    width: 340px;
    height: 320px;
  }
}

@media (max-width: 1280px) {
  :root {
    --space-xl: 80px 0;
    --space-pb-xl: 80px;

    /* font size  */

    --font-size-18: 16px;
    --h1-size-64: 48px;
    --h-size-36: 32px;
  }

  .accordion-img1 {
    top: -51px;
  }

  .feature-icons-box span {
    font-size: 16px;
  }

  .accordion-img2 {
    top: -120px;
  }

  .accordion-img3 {
    top: -190px;
  }

  .blog-filter-button {
    flex-wrap: wrap;
    gap: 10px;
  }

  .blog-filter-button button {
    margin: 0;
  }
}

@media (max-width: 1208px) {
  .srvc_card {
    width: 308px;
    height: 320px;
  }

  .srvc_card .srvc_animtext {
    padding-left: 52px;
  }

  .space-pb-200 {
    padding-bottom: 100px !important;
  }
}

@media (max-width: 991px) {
  .help-tab-responsive {
    display: block !important;
  }

  .carousel-caption {
    top: 50%;
  }

  .helpTab-panel {
    width: 100%;
    padding-left: 0;
  }

  #help-center-details .breadcrumbs {
    gap: 6px;
  }

  .contact-address-box {
    transform: translateY(-84px);
  }

  .contact-form>.MuiGrid-container {
    gap: 20px;
  }

  .contact-form>.MuiGrid-container .MuiGrid-item {
    padding-left: 0;
  }

  .sub-menuAccordion {
    border: none !important;
    box-shadow: none !important;
  }

  .sub-menuAccordion .MuiAccordionSummary-root {
    padding: 0 !important;
    min-height: 20px;
  }

  .sub-menuAccordion .MuiAccordionDetails-root {
    padding: 0 !important;
  }

  .sub-menuAccordion li {
    padding-left: 0 !important;
  }

  .manageCarer-cardContent h6 {
    padding-bottom: 8px;
    font-size: 16px;
  }

  .manageCarer-cardContent h3 {
    font-size: 28px;
  }

  #landing-page-features .owl-theme .owl-nav {
    top: 44%;
  }

  #landing-page-features .feature-tickIcon {
    width: 18px;
  }

  .feature-icons-box span {
    font-size: 16px;
  }

  #landing-page-features .manageCarer-cardContent {
    padding-left: 0 !important;
  }

  .landing-banner-none {
    display: none;
  }

  .landing-form-wrapper {
    transform: translateY(0);
    width: 100%;
    padding: 30px 0;
  }

  .landing-form {
    max-width: 60%;
    width: 100%;
    margin: auto;
  }

  .landing-banner h1 {
    font-size: 32px;
    text-align: center;
  }

  #landing-page-features .owl-theme .owl-nav {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .home-manage-section h1 {
    font-size: 30px;
  }

  #landingpage .manageCards-main {
    padding: 0 !important;
  }

  .home-client-slider {
    padding: 28px 0;
  }

  .client-slider-headingWrapper h1 {
    font-size: 24px;
  }

  .home-client-slider .owl-carousel .owl-nav .owl-prev,
  .home-client-slider .owl-carousel .owl-nav .owl-next {
    width: 40px;
    height: 40px;
  }

  .home-client-slider .owl-nav {
    top: -90px;
  }

  .client-homeSlide-content .text-box p {
    font-size: 20px;
  }

  .client-slide-names span {
    font-size: 18px;
  }

  .footer-listMobile-align {
    justify-content: flex-start !important;
  }

  .web-menu-drawwer .header-menu-link {
    margin-bottom: 0;
    /* padding: 10px 0; */
    padding: 4px 0;
  }

  .web-menu-drawwer .header-menu-custom {
    margin-top: -12px;
  }

  .web-menu-drawwer .header-menu-custom2 {
    margin-bottom: 21px;
    margin-top: 12px;
  }

  .web-menu-drawwer .header-menu-custom3 {
    margin-top: 23px;
  }

  .web-menu-drawwer .header-menu-custom4 {
    margin-top: 11px;
  }

  .web-menu-drawwer a,
  .web-menu-drawwer button {
    padding-top: 0;
    padding-bottom: 0;
  }

  .webContainer img {
    /* max-height: 240px; */
    max-height: 325px;
    object-fit: cover;
  }
}

@media (max-width: 576px) {
  :root {
    --p-left-80: 20px;
    --p-right-80: 20px;
    --space-80: 30px 0;
    --space-mt-80: 30px;
    --space-pb-60: 30px;
    --space-pt-60: 30px;
    --space-pb-40: 30px;
    --space-pt-40: 30px;
    --space-pt-80: 30px;
    --space-xl: 30px 0;
    --space-pb-xl: 60px;
    --space-120: 30px 0;
    --space-lg: 30px 0;
    --space-pb-120: 30px;

    --space-32: 25px 0;
    --space-pt-32: 25px;
    --space-24: 18px;
    --space-m-24: 18px;
    --space-mt-24: 18px;
    --space-mb-24: 18px;

    /* colors  */

    --font-size-18: 14px;
    --h1-size-64: 28px;
    --h-size-36: 24px;
    --f-size-22: 16px;
    --f-size-20: 16px;
  }

  .carousel-container .carousel-text {
    font-size: 38px;
  }

  .about-full-divider {
    margin: 20px 0;
  }

  .about-team-card {
    padding-top: 20px !important;
  }

  .webMenu-responsive-hide {
    display: none;
  }

  .featureBannerImg::after {
    left: 20px;
  }

  .feature-titleBox {
    padding: 30px 20px;
  }

  .help-details-wrapper {
    padding: 0 20px;
  }

  .home-content-main p {
    font-size: 14px;
  }

  .home-content-main p br {
    display: none;
  }

  .web-primary-btnLarge {
    width: 150px;
    height: 46px;
  }

  #home-logo-slider {
    padding: 40px 0 50px 0;
  }

  .manageCards-main {
    padding: 30px 0px;
  }

  .guides-details-text {
    width: 100%;
  }

  .guides-like-main {
    padding-left: 20px;
    padding-right: 20px;
  }

  .guide-info-image {
    transform: translateY(4px);
  }

  .guide-download-login {
    padding: 30px 20px;
  }

  .guide-download-login .web-primary-btnLarge {
    margin-left: 0;
    margin-top: 10px;
  }

  .manageCarer-cardContent {
    padding-left: 0;
  }

  .manageCarer-cardContent h3 {
    font-size: 20px;
  }

  .manageCarer-cardContent h6 {
    font-size: 16px;
  }

  .manageCarer-cardContent .manageCarer-cardText {
    padding: 30px 0;
  }

  .transforming-accordion {
    padding-left: 5px !important;
  }

  .manageCard-star-icon {
    min-width: 60px;
  }

  .feature-icons-box p {
    font-size: 20px !important;
  }

  .feature-icons-box span {
    font-size: 14px;
  }

  .feature-titleBox {
    padding: 40px 20px;
  }

  .featureBannerImg::after {
    width: 150px;
    height: 150px;
    background-size: contain;
    left: 22px;
  }

  .home-tab-videoWrapper {
    height: 300px;
  }

  .videoPlayer>div {
    height: 300px !important;
  }

  .home-tab-video iframe,
  .home-tab-video video {
    min-height: 300px;
  }

  .home-tab-wrapper .MuiTabs-scroller {
    overflow: scroll !important;
  }

  #homeTab .home-tabs-item {
    font-size: 14px;
    padding: 10px 11px;
  }

  .home-tab-wrapper {
    padding: 8px 0;
  }

  .bookDemo-main ul {
    flex-direction: column;
  }

  .bookDemo-main ul li {
    list-style: none;
  }

  .footer-logo {
    margin-bottom: 10px;
  }

  .bookDemo-wrapper {
    width: 100%;
  }

  .bookDemo-main .text,
  .bookDemo-main li {
    font-size: 16px;
  }

  .home-client-slider {
    padding: 30px 20px;
  }

  .home-client-slider .owl-carousel .owl-nav .owl-prev,
  .home-client-slider .owl-carousel .owl-nav .owl-next {
    width: 30px;
    height: 30px;
    background-size: 20px;
    margin-left: 4px;
  }

  .home-client-slider .owl-nav {
    right: -32px;
    top: -118px;
  }

  .client-homeSlide-content .text-box p {
    font-size: 16px;
  }

  .client-homeSlide-content {
    padding: 14px;
  }

  .client-slide-names {
    padding-top: 10px;
  }

  .client-slide-names span {
    font-size: 16px;
  }

  .client-homeSlide-content .text-box {
    height: 236px;
  }

  .home-transforming-section .pRight-80 {
    padding-right: 0 !important;
  }

  .home-module-tabMain {
    height: auto;
  }

  .blank-box {
    height: 50px;
  }

  /* .last-box {
    height: 90px;
  } */

  .home-client-slider .owl-carousel {
    height: 320px;
  }

  .bookDemo-main p {
    width: 86%;
  }

  #PricingMain .home-tab-wrapper {
    width: 345px;
  }

  #help-center-details .breadcrumbs {
    flex-wrap: wrap;
  }

  .contact-address-box {
    padding-top: 22px;
    padding-bottom: 22px;
  }

  .contact-address-box-wrapper {
    height: 460px;
  }

  .contact-map {
    padding-left: 0;
  }

  .contact-form .MuiGrid-container {
    gap: 20px;
  }

  .landing-banner {
    padding: 0px 0;
  }

  .landing-form {
    padding: 30px 30px !important;
  }

  .landing-banner h1 {
    font-size: 32px;
  }

  .landing-form-wrapper {
    transform: translate(0);
  }

  #landing-page-features .owl-theme .owl-nav {
    display: none;
  }

  #landing-page-features .owl-theme .owl-dots {
    bottom: -10px;
  }

  .tabVideo-tabpennel {
    position: static;
    transform: translate(0);
    padding-bottom: 30px;
  }

  .bookDemo-main h1 {
    font-size: 24px;
  }

  .transform-img-wrapper {
    margin-top: 10px;
  }

  .marquee {
    width: 332%;
  }

  #PricingMain .pricing-card-btn {
    /* width: 120px; */
    font-size: 12px;
  }

  .pricing-table thead th {
    font-size: 14px;
  }

  #home-logo-slider {
    padding-top: 0 !important;
  }

  .home-client-slider .owl-nav {
    top: -103px;
    right: -22px;
  }

  .client-slider-headingWrapper {
    padding-right: 18px;
  }

  .discover-slider-section .MuiGrid-item {
    padding-top: 0 !important;
  }

  .discover-slider-section h1 {
    margin-bottom: 30px;
  }

  .about-hubshift-img-wrapper {
    height: 80px;
  }

  .space-40 {
    padding: 20px 0;
  }

  .address-icons {
    margin-top: 20px;
  }

  .contact-address-box {
    transform: translateY(-75px);
  }

  .space-pb-200 {
    padding-bottom: 120px !important;
  }

  .contact-form .space-pl-40 {
    padding-left: 0 !important;
  }

  #demo-request .demo-request-wrapper {
    padding: 20px 15px;
  }

  #demo-request .fc-daygrid-day-events {
    top: -47px;
    right: -18px;
  }

  #demo-request .fc .fc-daygrid-day-frame {
    width: 30px;
    height: 30px;
  }

  #demo-request {
    padding: 20px 0;
  }

  #demo-request-calender .fc .fc-daygrid-day-number {
    font-family: "proxima_novaregular" !important;
    z-index: 1 !important;
    /* color: #202E2A !important; */
    color: red !important;
  }

  .export-market-filters {
    padding-left: 0 !important;
    margin-left: 0 !important;
  }

  .blog-card-smTitle {
    margin-top: 12px;
  }

  .blog-card-title {
    margin: 10px 0;
  }

  .blog-filter-button {
    justify-content: flex-start !important;
  }

  .free-guide-wrapper {
    flex-direction: column;
    align-items: flex-start !important;
  }

  #guides-details .guide-email-input {
    width: 100%;
    margin-right: 0;
  }

  .pricing-table th p {
    width: 138px;
    font-size: 14px;
  }

  .accordion-main-wrapper {
    width: 100%;
  }

  .home-section-button {
    margin-left: 0;
  }

  .manageCarer-cardImg {
    height: auto;
  }

  .feature-titleBox {
    padding-left: 0;
    padding-right: 0;
  }

  .manageCarer-cardImg img {
    object-fit: cover;
  }

  .help-tabs-content h6 {
    padding: 0;
  }

  .help-tabs-content ul li {
    padding: 8px;
  }

  .about-hubshift-img {
    transform: translateY(-128px);
  }

  .about-team-section p br {
    display: none;
  }

  .about-team-section p {
    padding: 10px;
  }

  .contact-form .form-input {
    margin-bottom: 0;
  }

  #getSupport .feature-titleBox {
    padding: 30px 0;
  }

  .timesheet-filter-show {
    width: auto;
    padding: 10px 12px;
    cursor: pointer;
  }

  .get-support-filter .agency-btn-section {
    margin-top: 20px;
  }

  .export-market-filters {
    margin: 10px 0 0 0 !important;
  }

  .get-support-search {
    padding-left: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .web-menu-drawwer button {
    justify-content: flex-start;
  }

  .main-home-section .homeBanner-main-container {
    height: 400px;
  }

  .main-banner .owl-theme .owl-nav {
    display: none !important;
  }

  .home-content-main p {
    padding: 20px 0;
  }

  .main-banner .home-section-button {
    width: 115px;
    height: 36px;
    font-size: 14px;
  }

  .homeBanner-main-container {
    background-size: 78%;
  }

  .landing-form {
    max-width: 88%;
  }

  .home-manage-section .owl-carousel {
    padding: 30px 0;
  }
}

@media (max-width: 600px) {
  .modal-slot-main {
    padding: 32px 42px;
    justify-content: center;
  }
}

@media only screen and (min-width: 918px) and (max-width: 1023px) {
  .pricing-points {
    height: 416px;
  }
}

.css-1tu3hxt-MuiStack-root {
  margin-bottom: 10px !important;
}