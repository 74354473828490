.record-page {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 0;
}

.record-page .totalcount {
  margin-top: 0;
  font-size: 14px;
}

.record-page .totalcount,
.record-page .pagination {
  flex: 1 0 0%;
  width: 100%;
  max-width: 100%;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  margin: 0 !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: var(--main-dark-color);
  border-color: var(--main-dark-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-link {
  cursor: pointer;
  position: relative;
  display: block;
  color: var(--main-dark-color);
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: "Open Sans", sans-serif;
  padding: 4px 12px ;
  line-height: normal;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.justify-content-right {
  justify-content: right !important;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  padding: 4px 10px;
  line-height: normal;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 4px 10px;
  line-height: normal;
}