/* 
=====================
Appointment Schedule Css Start
*/

.appbar-border {
  border-bottom: 0.5px solid #d0ece5;
}

.selected-day {
  background-color: #15a07d !important;
  color: #ffffff !important;
}

.headertext-sty.MuiTypography-root {
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  /* padding: 10px; */
}

.helpertext-sty.MuiTypography-root {
  margin-left: 10px;
}

.importexportsty {
  margin-right: 35px;
  float: right;
}

.importexportbtn {
  color: #645dd7 !important;
  border-color: #645dd7 !important;
  text-transform: capitalize !important;
  font-size: 16px;
  font-weight: 500 !important;
}

.importexporticon-image {
  width: 20.5px;
  }

/* popup style */

.cardcontent_box {
  background-color: #15a07d;
}

.import_CardContent {
  flex: 1 0 auto;
}

.importfiletext.MuiTypography-root {
  font-size: 14px;
  color: #ffffff;
}

.uploaddata.MuiTypography-root {
  margin-left: 12px;
  text-align: center;
  font-size: 10px;
  color: #ffffff;
}

.cardactionsty2 {
  margin-top: 15px;
}

.cardactionsty2 .filebtn {
  width: 140px;
  /* margin-left: 10px; */
  border-color: #d0ece5;
  color: #3d4244;
  text-transform: capitalize;
}

.importfilebtn {
  /* margin-left: 10px; */
  width: 140px !important;
  border-color: #d0ece5 !important;
  color: #3d4244 !important;
  /* text-transform: capitalize !important ; */
}

.slidertext-sty {
  width: 140px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px !important;
  margin: 6px !important;
  border: 1px solid #d0ece5 !important;
  border-radius: 6px !important;
}

.slidertext-sty .icons-color {
  color: #15a07d;
}

.checkbox-color.Mui-checked {
  color: #15a07d !important;
  margin: 0px;
}

#tabs .checkbox-label {
  padding: 20px 16px;
  border-radius: 8px;
}

.divider-line {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  width: 114%;
  background-color: #7299c7;
}

.formgroupsty {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

/* 
 =====================
 Appointment Schedule Css End
 */
/* 
 =====================
 Tabs Css Start
 */

.tab-container {
  border: 1px solid #15a07d;
  border-radius: 8px;
  min-height: 42px !important;
  max-width: fit-content;
  height: 1%;
  padding: 2px;
}

.tab-container .Tabstyle-test {
  border-radius: 8px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  min-height: 36px;
  font-size: 17px;
  text-transform: capitalize !important;
}

.MuiTabs-fixed .MuiTabs-indicator {
  display: none;
}

.tab-container .Tabstyle-test.Mui-selected {
  background: #15a07d;
  color: #fff;
  border: none !important;
}

.tab-container .Tabstyle-test:hover {
  background-color: #15a07d !important;
  color: #ffffff !important;
}

.Tabstyle-test .tab-text {
  color: #000000;
}

.Tabstyle-test .tab-text:hover {
  background-color: #15a07d !important;
  color: #ffffff !important;
}

.tabs-maincontainer {
  margin-left: 18px;
}

#document-table .table-head-cell {
  background: #e8f6f2 !important;
}

/* 
 =====================
 Tabs Css end
 */
/* 
 =====================
 table Css start
 */

.table-container {
  width: 100%;
  overflow-x: auto;
}

.all-round-borders {
  /* table-layout: fixed; */

  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow-x: hidden;
  /* overflow-y:visible ; */
  min-height: 180px;
}

.tableHeader {
  font-weight: 300 !important;
  color: #161616 !important;
  background-color: #e6e6e6 !important;
}

.tableHeaders {
  font-weight: 300 !important;
  color: #161616 !important;
  background-color: #e2ecf1 !important;
}

.all-round-borders th,
.all-round-borders td {
  border: 0.2px solid #e8f6f2;
  padding: 16px;
  text-align: left;
  /* white-space: nowrap; */
}

@media only screen and (max-width: 600px) {

  .all-round-borders th,
  .all-round-borders td {
    padding: 8px;
  }
}

.routine-iconcolor {
  color: #15a07d;
}

.routine-iconcolor span {
  text-decoration: underline;
  color: #15a07d;
}

.tableswap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tableswap .swap-icon {
  text-align: center;
  color: #924cbd;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Leave management status css start*/
.status-cell-16,
.appointment-status.status-cell-16 {
  background-color: #fff3c8;
  color: #ffc700;
  position: relative;
}

.status-cell-17,
.appointment-status.status-cell-17 {
  background-color: #c5fff1;
  color: #02a37c;
  position: relative;
}

.status-cell-18,
.appointment-status.status-cell-18 {
  background-color: #ffdddf;
  color: #f02d3a;
  position: relative;
}

.status-cell-19,
.appointment-status.status-cell-19 {
  background-color: #ffe2c8;
  color: #f17300;
  position: relative;
}

.status-cell-20,
.appointment-status.status-cell-20 {
  background-color: #b5e6f7;
  color: #00a7e1;
  position: relative;
}

.status-cell-21,
.appointment-status.status-cell-21 {
  background-color: #affed0;
  color: #109648;
  position: relative;
}

/* Leave management status css end*/

.status-cell-1,
.appointment-status.status-cell-1 {
  background-color: #fdeeba;
  color: #e8b500;
  position: relative;
}

/* Use for Swap and approved*/

.status-cell-2,
.appointment-status.status-cell-2 {
  background-color: #affed0;
  position: relative;
  color: #109648;
}

.parRun-status-success {
  background-color: #affed0 !important;
  position: relative;
  color: #109648 !important;
}

.status-cell-3,
.appointment-status.status-cell-3 {
  background-color: #ccf4f8;
  position: relative;
  color: #3cbbc8;
}

.status-cell-4,
.appointment-status.status-cell-4 {
  background-color: #c9c4ff;
  position: relative;
  color: #5d53c4;
}

.status-cell-5,
.appointment-status.status-cell-5,
.badge-reject {
  position: relative;
  background-color: #ffe8e8 !important;
  color: #ff5251 !important;
}

.status-cell-6,
.appointment-status.status-cell-6 {
  background-color: #ffeba4;
  position: relative;
  color: #e8b500;
}

.status-cell-7,
.appointment-status.status-cell-7 {
  background-color: #a4ffb3;
  position: relative;
  color: #00a11a;
}

.status-cell-8,
.appointment-status.status-cell-8 {
  position: relative;
  background-color: #ccf4f8;
}

.status-cell-9,
.appointment-status.status-cell-9 {
  background-color: #c9c4ff;
  position: relative;
  color: #5d53c4;
}

.status-cell-10,
.appointment-status.status-cell-10 {
  background-color: #f5dcee;
  position: relative;
  color: #875379;
}

.status-cell-11,
.appointment-status.status-cell-11 {
  background-color: #ffeba4;
  position: relative;
}

.status-cell-12 {
  background-color: #a4ffb3;
  position: relative;
}

.status-cell-13 {
  background-color: #ccf4f8;
  position: relative;
}

.status-cell-14 {
  background-color: #c9c4ff;
  position: relative;
}

.status-cell-15 {
  position: relative;
  background-color: #ffcdcd;
}

/* table cell end  */

.tablebutton-section {
  background-color: #d0ece5;
  /* padding: 15px 0; */
}

.buttom-check {
  padding: 0px 10px;
  background-color: transparent;
  border-radius: 8px;
}

.buttom-check2 {
  padding: 8px 16px;
  background-color: transparent;
  border-radius: 8px;
}

.centered-menu {
  display: flex;
  justify-content: center;
  text-align: center;
}

.bg-none {
  background-color: transparent !important;
}

/* Media Queries  */

@media only screen and (max-width: 600px) {
  .headertext-sty.MuiTypography-root {
    font-size: 20px;
  }
}

.custom-outline-red {
  outline: 1px solid #d32f2f;
}
/* 
.css-apme4c-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 1px 4px 7.5px 5px !important;
}

.css-i4bv87-MuiSvgIcon-root {

  margin-bottom: 8px !important;
} */