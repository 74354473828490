.coming-soon-container {
    background-color: #333;
    font-family: 'Arial', sans-serif;
    text-align: center;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .coming-soon-line {
    font-size: 24px;
    color: #333; /* Dark text color */
    margin-top: 10px;
  }
  
  .coming-soon-content {
    background-color: #fff;
    max-width: 400px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  
  .heading {
    font-size: 36px;
    color: #333;
    margin-top: 0;
  }
  
  .sub-heading {
    font-size: 18px;
    color: #777;
  }
  
  .countdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .countdown-item {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 24px;
  }
  
  .countdown-item span {
    font-size: 36px;
    font-weight: bold;
  }
  
  .countdown-item p {
    font-size: 14px;
  }
  