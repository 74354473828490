/* .sa-onboard-browes{
    height: 40 !important;
    height: 42px !important;
    border: 1px solid #DAE5EB !important;
    border-radius: 4px !important;
} */

.sa-onboard-browes-btn .MuiButtonBase-root {
  padding: 8px !important;
}

.sa-onboard-lable.MuiTypography-root {
  margin-bottom: 10px !important;
}

/* ----------------------------------------- */

.hc-edit-btn {
  width: 181px !important;
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #15a07d;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  color: #15a07d;
  /* Set your desired text color */
}

.sa-master-list {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.Send-document-btn.MuiButton-root {
  text-transform: capitalize;
  color: #645dd7;
  font-weight: normal;
  font-size: 16px;
}

.ap-agreement-btn {
  font-size: 16px;
  font-family: "proxima_novasemibold" !important;
  line-height: 16px;
}

.sa-gold-icons .css-ptiqhd-MuiSvgIcon-root {
  display: none !important;
}

.view-sign.MuiTypography-root {
  color: #3d4244 !important;
}

.taskpageaddbutton {
  color: blue !important;
  text-transform: capitalize !important;
}

.explore-market-cards {
  /* padding: 0px 0px 0 40px; */
  padding-right: 0px;
}

.date-picker1 .rc-time-picker-input {
  background: url("../images/Timer-icons.svg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: 17px;
  background-position: calc(100% - 5px) center;
}

.rc-time-picker-clear-icon:after {
  content: "" !important;
  font-size: 12px !important;
  font-style: normal !important;
  color: #aaa !important;
  display: inline-block !important;
  line-height: 1 !important;
  height: 20px !important;
  width: 20px !important;
  transition: color 0.3s ease !important;
}

.date-width {
  width: 189px !important;
}

.doc-modal-content.MuiTypography-root {
  font-size: 20px;
  color: #484747;
  font-family: "proxima_novasemibold";
  padding-bottom: 32px;
}

.hc-calenderdata-img {
  width: 42px;
}

.hc-calenderdata-span {
  margin-top: 2px;
}

.selectedRoutine {
  background-color: rgb(21 160 125 / 10%);
  border-radius: 12px;
  padding: 10px;
}

.add-appi-dropdown {
  width: 287px !important;
}

.bsp-text.MuiTypography-root {
  font-size: 16px;
  font-weight: 600;
  color: #15a07d;
}

.bsp-lable.MuiTypography-root {
  font-size: 16px;
  /* font-weight: 600; */
  color: #3d4244;
}

.bsp-lable.MuiTypography-root span {
  font-weight: 600;
}

.bsp-content-box {
  padding: 5px;
  padding-top: 14px;
  padding-bottom: 14px;
  background: #e8f6f2;
  border-radius: 7px;
}

.sh-risk-check {
  color: Green;
}

.sh-risk-croscheck {
  color: red;
}

.sh-risk-lable.MuiTypography-root {
  font-size: 16px;
  font-weight: 600;
  color: #3d4244;
}

.taxdetails-lable {
  /* margin-left: -8px; */
  margin-bottom: 20px !important;
}

.taxdetails-box {
  margin-bottom: 15px;
  margin-left: 0px !important;
}

.taxdetails-svg {
  color: #645dd7;
}

.taxdetails-date-svg {
  color: #969696;
  margin-top: -2px !important;
}

.obsevarion-container {
  margin-top: 7px;
  margin-left: 20px;
}

.add-btn-observation {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.obsevarion-tabs-bg .css-1aquho2-MuiTabs-indicator {
  background-color: #15a07d !important;
}

.chat-container-obs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sp-myaccount-camera {
  position: absolute;
  left: 227px;
  margin-top: -47px;
}

.calender-add-icon-newcolor {
  /* color: #15A07D !important; */
  margin-left: 6px !important;
  font-size: 17px !important;
}

.calender-add-icon-newcolor-hc {
  color: #15a07d !important;
  margin-left: -55px !important;
}

.button-no-hover:hover,
.button-no-hover:focus,
.button-no-hover:active,
.button-no-hover:focus-visible {
  background-color: inherit !important;
  outline: none !important;
  box-shadow: none !important;
}

.calender-add-icon-newcolor2 {
  height: 44px !important;
  border-radius: 50% !important;
  margin-bottom: 2px !important;
  margin-left: -45px !important;
}

@media (min-width: 769px) {
  .calender-add-icon-newcolor2 {
    margin-bottom: 22px !important;
  }
}

@media (max-width: 768px) {
  .calender-add-icon-newcolor2 {
    margin-bottom: 42px !important;
  }
}

@media (max-width: 480px) {
  .calender-add-icon-newcolor2 {
    margin-bottom: 42px !important;
  }
}

.hc-model-duc.MuiTypography-root {
  font-size: 20px;
  color: #484747;
  font-family: "proxima_novasemibold";
  padding-bottom: 32px;
}

.cleint-edit-location-icon {
  color: #645dd7;
}

.client-add-location-container {
  padding: 0px 50px 0 45px;
}

.client-add-location-btn {
  width: 132px;
  height: 39px;
  font-size: 15px;
  font-family: "proxima_novasemibold";
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #645dd7;
  color: #645dd7;
  border-radius: 6px;
  background: none;
  cursor: pointer;
}

.client-divider {
  width: 115%;
  margin-left: -17px !important;
}

.client-Allergies-list {
  padding-left: 16px !important;
  list-style-type: disc !important;
  list-style-position: inside !important;
}

.client-Allergies-cell {
  list-style: disc !important;
  display: list-item !important;
}

.client-Allergies-cell {
  list-style: disc !important;
  display: list-item !important;
}

.client-Allergies-text {
  white-space: nowrap;
}

.fund-manage-tab-container {
  max-width: 253px;
  margin-top: 30px;
  margin-left: 20px;
}

.notification-header-icon.MuiTypography-root {
  margin-left: 10px;
  margin-top: 3px;
}

.char-header-icon {
  margin-right: 20px;
}

.view-sign {
  /* height: 80px; */
  width: 250px;
}

.export-market-filters {
  margin-top: 34px;
  margin-left: -30px;
}

.medication-card-img {
  object-fit: cover !important;
  width: 100%;
}

.hr-view-sign {
  width: 170px;
}

.hr-sign-container {
  width: 200px;
  height: 80px;
}

.live-roster-border.MuiDivider-root {
  border-color: #d0ece5;
}

.live-roster-res-text {
  color: #15a07d;
  font-weight: 600;
}

.um-client-Category-text {
  white-space: nowrap;
  padding: 3px;
}

.sign-box.MuiButton-root {
  border-color: gray !important;
  color: #3d4244;
  text-transform: capitalize;
}

.my-ac-sign-btn.MuiButton-root {
  background-color: #15a07d !important;
  color: #fff;
  text-transform: capitalize;
  white-space: nowrap;
}

.bloodpressureinfo-status {
  padding: 8px 12px;
  background-color: #f0f0f0;
  border-radius: 40px;
}

.response-status {
  /* background-color: #a3df3d;
    color: #f05208;
    position: relative; */
  position: relative;
  background-color: #ffe8e8 !important;
  color: #ff5251 !important;
  white-space: pre;
}

.type-status {
  /* background-color: #c6f00e;
    color: #c0d40f;
    position: relative; */
  background-color: #ffeba4;
  position: relative;
  color: #e8b500;
}

.Sh-header-btn.MuiButton-root {
  /* border-color: #15a07d !important; */
  color: #15a07d !important;
  text-transform: capitalize;
  white-space: nowrap;
  background: #d0ece5 !important;
  border-right: unset !important;
}

.Sh-header-svg.MuiButton-root {
  /* border-color: #15a07d !important; */
  color: #15a07d !important;
  text-transform: capitalize;
  white-space: nowrap;
  background: #d0ece5 !important;
}

.Sh-header-svg.MuiButton-root svg {
  color: #15a07d !important;
}

.Signin-link {
  color: #1976d2 !important;
}

.Signin-demo-link {
  color: #15a07d !important;
}

.demo-meeting-profile {
  max-width: 300px;
}

.medicalchart-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.calcel-aap-dd input:-webkit-autofill,
.calcel-aap-dd input:-webkit-autofill:hover,
.calcel-aap-dd input:-webkit-autofill:focus,
.calcel-aap-dd input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
  -webkit-text-fill-color: #fff !important;
}

.custom-sign-field {
  padding-left: 0;
  padding-right: 0;
}

.um-client-table {
  min-height: 400px;
}

.appt-calender-checkbox {
  padding: 0 10px;
  margin-bottom: 15px;
}

.viewtab-top-btn.MuiButton-root {
  border-color: #15a07d !important;
  color: #15a07d;
  text-transform: capitalize;
}

.appt-apptadd-expence button {
  width: 136px;
  padding: 10px 0;
  border: 1px solid #15a07d;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  background: #fff;
  margin-right: 20px;
  color: #15a07d;
  font-family: "proxima_novasemibold";
}

.bullet-metting-pdf {
  list-style: disc !important;
  display: list-item !important;
  margin-left: 20px;
}

.Progress-list-dropdown {
  /* top: -30% !important; */
  margin-top: -30px !important;
}

.span-metting-pdf {
  font-weight: normal;
}

.calender-contentcopy-icon {
  color: #15a07d !important;
  margin-left: 7px !important;
}

.calender-edit-icon {
  color: #645dd7 !important;
  margin-left: 7px !important;
}

.calender-delete-icon {
  color: rgb(245, 171, 171) !important;
  margin-left: 7px !important;
}

.bold-total {
  font-weight: bold !important;
  color: #15a07d !important;
}

.background-total {
  background: rgb(218 229 235 / 35%);
}

.attack-duc-img {
  width: 132px;
}

.task-pedning-main {
  background: #e3e8e666;
  padding: 26px 10px 21px 10px;
  border-radius: 15px;
}

.task-pedning-main-height {
  height: 450px;
  width: 310px;
}

.task-dashboard-panel {
  white-space: nowrap !important;
  font-weight: bold !important;
}

.Drag-mb {
  margin-bottom: 20px;
}

.task-pedning-track {
  background-color: #fff !important;
  border-radius: 50% !important;
  height: 28px !important;
  width: 28px !important;
  padding: 1px 0px 0px 9px !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.task-pedning-bg {
  background: #fff;
}

.task-pedning-viewall-btn {
  color: #15a07d !important;
  text-transform: capitalize !important;
}

.header-notification-icon.MuiIconButton-root {
  margin-left: 0px !important;
  padding: 1px !important;
}

.header-task-board.MuiTypography-root {
  margin-top: -4px;
}

.header-myaccount {
  margin-bottom: 17px;
  margin-left: 17px;
}

.help-header.MuiTypography-root {
  font-weight: bolder;
  font-size: 25px;
}

.help-sub-header.MuiTypography-root {
  font-weight: bold;
  font-size: 18px;
}

.help-card-media {
  border-radius: 18px;
  width: 100%;
}

.help-card-media {
  height: 100%;
  width: 482px;
}

.progress-week-label.MuiTypography-root {
  color: #202e2a;
  font-size: 16px;
}

.sub-badge .css-106c1u2-MuiBadge-badge {
  top: 8px !important;
  right: -15px !important;
  background-color: #fdd443 !important;
  color: black !important;
  border-radius: 4px !important;
}

.sub-expired-badge .css-106c1u2-MuiBadge-badge {
  top: 8px !important;
  right: -15px !important;
  background-color: #f5979d !important;
  color: black !important;
  border-radius: 4px !important;
}

.night-shift {
  background-color: #0f4c75;
  /* Example color for Night */
}

.sleepover-shift {
  background-color: #c9ada7;
  /* Example color for Sleepover */
}

.general-shift {
  background-color: #1b262c;
  /* Example color for General */
}

.invoice-download-pdf-btn.MuiButtonBase-root {
  background-color: #15a07d !important;
  height: 38px;
  text-transform: capitalize;
}

.dropdown-menu-custom-leave {
  margin-top: -30px;
}

.dropdown-menu-incident {
  margin-top: -20px !important;
  margin-bottom: -10px !important;
}

.funds-manage-box {
  background-color: #15a07d1a;
  padding: 16px;
}

.funds-manage-box p {
  color: #15a07d;
  font-weight: 600;
}

.leave-filter-svg .css-ptiqhd-MuiSvgIcon-root {
  display: none !important;
}

.funds-tabs-nowrap {
  white-space: nowrap !important;
}

/* .removescroll {
  overflow-x: unset !important;
} */

.funds-limit-bg.MuiTypography-root {
  background-color: #f02d3a;
  border-radius: 5px;
  padding: 5px;
  width: fit-content;
  height: 33px;
  color: #fff;
}

.cell-removebg {
  background: transparent !important;
}

.silsda-total-cell {
  background: #e3e8e6b2;
}

.silsda-total-cell-icons {
  color: #15a07d;
  cursor: pointer;
}

.sil-sda-cell-span {
  white-space: nowrap;
  font-size: 12px;
  color: #929495;
}

.sil-sda-week-span {
  background: #fdd443;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
}

/* color code silsda */

.sil-completed-cell-bg {
  background: #affed0;
}

.sil-accepted-cell-bg {
  background: #c5fff1;
}

.sil-rejected-cell-bg {
  background: #ffdddf;
}

.sil-pending-cell-bg {
  background: #fff3c8;
}

.sil-cancelled-cell-bg {
  background: #ffe2c8;
}

.custom-invoice-label {
  font-size: 16px !important;
}

.no-sign-img {
  visibility: hidden;
}

/* Hide this section by default */
.hide-on-view {
  display: none;
}

/* Hide this section by default */

.payrun-btn {
  background-color: #15a07d !important;
  color: #fff !important;
  font-weight: 500 !important;
  border-radius: 7px !important;
}

.payrun-btn2 {
  background-color: #15a07d !important;
  color: #fff !important;
  font-weight: 500 !important;
  /* height: 31px !important; */
  text-transform: capitalize !important;
  border-radius: 7px !important;
}

.mt-10 {
  margin-top: 10px;
}

/* Global CSS */
.custom-input-container {
  width: 150px;
  position: relative;
}

.custom-input-box {
  width: 100%;
  padding: 10px 40px 10px 10px;
  /* Add padding to accommodate the clear icon */
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}

.custom-clear-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999;
}

.custom-clear-icon:hover {
  color: #666;
}

/* Style for the datalist dropdown */
.custom-datalist {
  max-height: 150px !important;
  overflow-y: auto !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  background-color: #fff !important;
}

datalist {
  position: absolute;
  background-color: white;
  border: 1px solid blue;
  border-radius: 0 0 5px 5px;
  border-top: none;
  font-family: sans-serif;
  width: 350px;
  padding: 5px;
}

/* Style for the options in the datalist */
.custom-option {
  padding: 5px 10px;
}

/* Style for the placeholder text */
.custom-input-box::placeholder {
  color: #999;
}

/* Style for when the input is focused */
.custom-input-box:focus {
  border-color: #15a07d;
}

/* Custom CSS */
/* Style for the options in the datalist */
.custom-styletext .custom-option {
  color: #000;
  /* Set text color of options */
}

.medication-chart-popover {
  width: fit-content !important;
}

/* _____________ */

@media only screen and (max-width: 768px) {
  .custom-sign-field {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.timesheet-expand-main {
  background: #f2f2f2 !important;
  border: 1px solid #d0ece5 !important;
}

.timesheet-circle {
  background: #15a07d !important;
  border-radius: 15px;
  padding: 5px 10px;
  width: 30px;
  height: 30px;
  color: white;
}

.client-alerts-scrollbar {
  height: 453px;
  overflow-y: auto;
}

.admin-payslip-cell-1 {
  width: 38px !important;
  height: 47px;
  object-fit: contain !important;
}

.avil-calendar-data {
  color: #15a07d;
  margin-bottom: -45px !important;
}

.emp-details-eport-icon {
  color: #ff6c6c !important;
  font-size: 18px !important;
}

.carousel-container-a {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel__slide-list-a {
  height: 100%;
  left: 50%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translateX(-50%);
  width: calc(330rem);
}

.carousel__slide-item-a {
  display: inline-block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

.carousel__slide-item-img-link-a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide-item-a img,
.carousel__slide-item-a video {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.sa-dashboard-icon-mr {
  margin-right: -6px;
}

.spcalender-data {
  font-size: 18px !important;
  font-weight: bold !important;
}

.client-avil-sent-icon {
  margin-top: 8px !important;
  margin-right: 8px !important;
}

.client-avil-sent-icon svg {
  color: #15a07d !important;
}

.appt-view-typography {
  color: #15a07d !important;
  font-weight: 600 !important;
}

.calender-icons-box {
  float: right;
}

.client-buttons-box {
  margin: 0;
}

.calender-icons-box span {
  color: #fff !important;
}

.viewgallery-custom-box {
  position: relative;
}

.viewgallery-custom {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "proxima_novasemibold";
  font-size: 13px;
}

/* ----------- */

.custom-gallery-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-viewgallery {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  /* Semi-transparent background */
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  z-index: 10;
}

.custom-routinetask-img {
  /* max-width: 70%; */
  object-fit: fill;
  margin: 10px;
  width: 260px;
  height: 150px;
  max-width: 100%;
}

.sp-reject-formik {
  font-family: "proxima_novaregular" !important;
  font-size: 14px !important;
  color: #d32f2f !important;
}

.circumstance-container {
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  white-space: normal !important;
}

.symptoms-width {
  min-width: 319px !important;
}

.avil-calendar-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.avil-filter-clear {
  margin-top: 7px;
}

.avil-calender-popup {
  padding-bottom: 8px;
}

.avil-calender-popup-p {
  padding-top: 6px;
}

.team-popover-img-custom {
  width: 100% !important;
  height: 100% !important;
}

.team-popover-img-custom img {
  /* width: 70% !important; */
  /* height: 60% !important; */
  height: 70px !important;
  width: 100px !important;
}

.calender-box-shadow {
  box-shadow: none !important;
}
