.thankyou-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

#thankyou .thankyou-card {
    box-shadow: 0px 5px 12px rgba(1, 46, 70, 0.1);
    border: none;
}

.thankyou-box .check-circle-icon {
    color: #4ecb71;
    font-size: 80px;
}

.thankyou-box .buttonsty {
    text-transform: capitalize;
    background-color: #15a07d;
}

.thankyou-box .buttonsty a {
    color: #fff;
    font-family: "proxima_novasemibold";
}

.red-color {
    color: red;
}