.header {
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 0 10px #f9f9f9;
    padding: 5px 0;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    width: 80px;
}

.logo img {
    width: 100%;
}

.logoalign{
    margin-left: -92px;
}

.navbar-item-box {
    max-width: 388px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-right: 83px;
    justify-content: space-between;
}

.menu-link{
    padding: 8px 16px;
    /* padding: 8px 100px; */
}
.menu-link-text.MuiTypography-root{
    color: var(--text-color);
    font-size: 16px;
    padding: 1px;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
    /* text-transform: capitalize !important; */
}

.menu-link-icons{
    color: #15a07d;
}

/* .menu-link:hover {
    background: var(--main-color);
    color: #fff;
    border-radius: 50px;
} */

.navbar-profile-box {
    min-width: 171px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar-profile-align{
    margin-right: -100px;
}

.menu-toggle {
    display: none;
}