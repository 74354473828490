.client-profile-table {
    padding: 0 0 0 32px;
}

.clientprofile .table-category-cell {
    width: 100%;
}

.clientprofile .table-sr-no {
    width: 100px !important;
}

.clientprofile .table-edit img {
    margin-right: 8px;
}


.table-download {
    color: #AC5252;
}

.table-download img {
    margin-right: 8px;
}

#profileclient .add-appointment-input-box {
    padding-right: 0 !important;
}

.table-search {
    padding-left: 20px;
    padding-bottom: 20px;

}

#profileclient {
    margin: 20px 10px 40px 35px;
    box-shadow: 0px 4px 15px 0px rgba(22, 22, 22, 0.20);
    background: #fff;
    padding-top: 0;
    overflow: hidden;
}
.client-profile-card{

    width: 80% !important;

    margin-left: 119px !important;

}
.profile-heading {
    padding: 40px;
    margin-top: 40px !important;
    margin-bottom: 40px;
    border-top: 1px solid #dae5eb;
    border-bottom: 1px solid #dae5eb;


}

.profile-heading p {
    color: #222;
    font-size: 24px;
    font-weight: 600;
}

.profile-box {
    padding: 15px 20px !important;
    background: #DAE5EB;
    margin-bottom: 40px !important;
}
/* Added aproove and reject button css */
.aprvbtn{

     background-color:#4ECB71!important;
    
    color:#ffffff!important;
    
    text-transform:capitalize!important;
    
    }
    
    .rjtbtn{
    
    border-color:#FF0000!important;
    
    color:#FF0000!important;
    
    text-transform:capitalize!important;
    
    }

    .zip-down-btn{
    text-transform:capitalize!important;
    }
    .zip-down-btn svg{  
     color: #006ca6;
    }

    .skill-data {
        background-color: #E2ECF2;
        color: #006CA6 !important;
        padding: 5px;
        border-radius: 10px;
      }
      

@media (max-width: 1440px) {
    .client-profile-table {
        padding: 0 50px 0 32px;
    }
}

@media (max-width: 1320px) {
    .clientprofile #document-table .table-sr-no {
        width: 100% !important;
        min-width: 50px !important;
    }

    .clientprofile .table-sr-no {
        width: 60px !important;
    }

    #document-table .table-sr-no {
        min-width: 60px !important;
    }

    #profileclient {
        margin-left: 15px;

    }



    .profile-heading {
        padding: 15px;

    }

    .client-profile-table {
        padding: 0 0px 0 15px;
    }

}
