.agency-btn-section-vk button {
  width: 136px;
  padding: 10px 0;
  border: 1px solid #15a07d;
  background-color: #15a07d;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  /* font-size: 16px; */
}

.agency-btn-section-vk a {
  line-height: 15px;
}

.agency-btn-section-vk-policy button {
  width: 200px;
  padding: 10px 0;
  border: 1px solid #15a07d;
  background-color: #15a07d;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  /* font-size: 16px; */
}

/* .agency-btn-section-vk button:nth-child(1) {
    color: #fff;
    background-color: var(--main-dark-color);
  } */

.agency-btn-section-vk button:nth-child(2) {
  color: #fff;
  background-color: var(--main-dark-color);
  cursor: pointer;
}

.agency-btn-section-lead button {
  width: 30px;
  height: 40px;
  padding: 5px 0;

  color: #fff;
  text-align: center;
}

.agency-btn-section-lead .enabled-button {
  background-color: #15a07d;
  border: 1px solid #15a07d;
}

.agency-btn-section-lead .disabled-button {
  background-color: gray;
  border: 1px solid gray;
}

.dashboard-form .agency-form-box-btn-custom {
  /* margin-top: 23px !important; */
  margin-top: 0px !important;
}

.dashboard-form .agency-searchbar-custom {
  margin-top: 0px !important;
}

.dashboard-form .agency-form-box-btn-custom a {
  padding: 8px 16px;
  font-size: 16px;
  color: var(--main-dark-color);
  font-size: 16px;
}

.dashboard-form .agency-form-box-btn-custom a:nth-child(1) {
  background-color: #e8f6f2;
  border-radius: 8px;
}

.searchbox-margin {
  /* margin-bottom: 50px !important; */
  margin-bottom: 20px !important;
  margin-top: 1.5rem !important;
}

.searchbox-margin-sc {
  /* margin-bottom: 50px !important; */
  margin-bottom: 20px !important;
  margin-top: 0.5rem !important;
}

.searchbox-margin-new {
  /* margin-bottom: 50px !important; */
  margin-bottom: 20px !important;
  margin-top: 3.4rem !important;
}

/* User management Add new Button css */

.client-btn a {
  margin-left: 15px;
  width: auto;
  padding: 2px 10px;
  background-color: #15a07d;
  color: #fff;
  border-radius: 8px;
  text-align: center;
  font-size: 15px;
  font-weight: 300;
}

.company-img-logo-vk {
  margin-top: 15px;
  height: 100px;
  width: 100px;
}

/* User management Add new Button css */

/* healthCarer-leaveManagement-list css Start  */

.hc-leave-Pending-text {
  color: yellow;
  margin-bottom: -6px;
}

.hc-leave-Rejected-text {
  color: pink;
  margin-bottom: -6px;
}

.hc-leave-Approved-text {
  color: green;
  margin-bottom: -6px;
}

.hc-leave-Cancel-text {
  color: orange;
  margin-bottom: -6px;
}

.peroid-cell {
  min-width: 98px !important;
  text-align: center !important;
  padding: 5px 0 !important;
  color: #929495 !important;
}

/* healthCarer-leaveManagement-list css Start  */

/* SuperAdmin-leaveManagement-list css Start  */

.table-head-bg {
  background-color: #e8f6f2;
}

.sa-leave-Pending-text {
  color: yellow;
  margin-left: 10px;
}

.sa-leave-Rejected-text {
  color: pink;
  margin-left: 10px;
}

.sa-leave-Approved-text {
  color: green;
  margin-left: 10px;
}

.sa-leave-Cancel-text {
  color: orange;
  margin-left: 10px;
}

.peroid-cell2 {
  min-width: 95px !important;
  text-align: center !important;
  padding: 5px 0 !important;
  color: #929495 !important;
}

/* SuperAdmin-leaveManagement-list css End  */

/* healthCarer-leaveManagement-list css  */

/* Package Menu css*/

.menu-card {
  /* border: 1px solid #15A07D; */
  border-radius: 12px !important;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.menu-card-green {
  background-color: #15a07d !important;
  border: 1px solid white !important;
}

/* .cardheader-bg {
 
} */

.green-bg {
  /* background-color: #1cb722; */
  /* margin-bottom: 40px; */
  /* margin-bottom: 5px; */
  color: #ffffff;
}

.menu-cardheader.MuiTypography-root {
  text-align: center;
  /* font-size: 20px; */
  font-size: 16px;
  font-weight: 600;
}

.pricingcard-sub-month.MuiTypography-root {
  color: #fff;
  font-size: 18px;
  font-family: "proxima_novaregular";
}

.pricingcard-non-sub-month.MuiTypography-root {
  color: #8c8c8c;
  font-size: 18px;
  font-family: "proxima_novaregular";
}

.menu-cardsubheader.MuiTypography-root {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
}

.menu-curent-cardsubheade.MuiTypography-root {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
}

.cardcontent-text {
  color: #000000;
}

.curent-cardcontent-text {
  color: #ffffff;
}

.right-tick {
  color: green;
}

.curent-right-tick {
  color: #affed0;
}

.start-button.MuiButton-root {
  border-radius: 10px !important;
  width: 120px;
  text-transform: capitalize;
  color: #8c8c8c;
  border-color: #8c8c8c !important;
  font-size: 16px;
}

.subscribe-button.MuiButton-root {
  border-radius: 7px;
  width: 150px;
  height: 45px;
  text-transform: capitalize;
  color: #fff !important;
  background-color: #15a07d !important;
  font-size: 14px;
}

.subscribe-button3.MuiButton-root {
  border-radius: 7px;
  width: 150px;
  height: 45px;
  text-transform: capitalize;
  color: #000 !important;
  background-color: #fff !important;
  font-size: 14px;
}

.subscribe-button2.MuiButton-root {
  border-radius: 7px;
  width: 150px;
  height: 45px;
  text-transform: capitalize;
  color: #15a07d !important;
  background-color: #fff !important;
  font-size: 14px;
}

.subscribe-btn-trial.MuiButton-root {
  border-radius: 7px;
  width: 150px;
  height: 45px;
  text-transform: capitalize;
  color: #fff !important;
  background-color: #645dd7 !important;
  font-size: 14px;
}

.align-text {
  text-align: center;
  margin-left: 250px;
  margin-bottom: 50px;
}

.subscribe-button-header.MuiButton-root {
  border-radius: 7px;
  /* height: 45px ; */
  text-transform: capitalize;
  color: #fff !important;
  background-color: #15a07d !important;
  font-size: 14px;
}

/* My Account */

.label-color-ac {
  color: #3d4244;
}

/* severity for incident */
.severity-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.severity-box {
  display: flex;
  align-items: center;
}

.currentplan-box {
  width: 120px;
  border-radius: 10px;
  padding: 11px;
  margin: 7px;
  background-color: #ffffff;
}

.currentplan-label {
  font-size: 16px !important;
  color: #15a07d;
}

.card-footer {
  margin-top: auto;
  display: flex;
  justify-content: center;
}

.card-footer-free {
  margin-top: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
}

/* task list status */

.status-pending {
  background-color: #ffeba4;
}

.status-rejected {
  background-color: #ffcdcd;
}

.status-approved {
  background-color: #95f06e;
}

/* super-admin/import-ndis button css start */

.ndis-file-upload.MuiButton-root {
  text-transform: capitalize;
}

.ndis-file-upload.MuiButton-root svg {
  color: #15a07d;
}

.import-ndis.MuiButton-root {
  background-color: #15a07d !important;
  color: #fff;
  text-transform: capitalize;
}

.import-header-file.MuiButton-root {
  background-color: #645dd7 !important;
  color: #fff;
  text-transform: none !important;
}

.downcsv.MuiButton-root {
  text-transform: capitalize;
}

.downcsv.MuiButton-root svg {
  color: #15a07d;
}

.downcsv-link {
  color: #121212;
}

.green-text {
  color: green;
}

/* super-admin/import-ndis button css end */

.aad-appt .rc-time-picker-input {
  height: 40px !important;
  width: 150px;
  font-size: 15px;
}

.task-board .rc-time-picker-input {
  width: 100% !important;
  margin: 5px 0;
}

.formControlLabelContainer {
  background-color: rgba(21, 160, 125, 0.1) !important;
  margin-left: 0px !important;
  padding: 8px;
  padding-right: 22px;
}

/* pagination style start */

.totalcount.MuiTypography-root {
  color: #15a07d;
}

/* pagination style end */

/* appointment agreement style start */

.termsCondition {
  color: rgb(211, 47, 47);
}

/* appointment agreement end */

/* appointment list style start */

.statusColor {
  background-color: #c9c4ff;
  position: relative;
  color: #5d53c4;
}

/* appointment list end */

/* appointment view style start */

.apptSwappedViewColor {
  text-size-adjust: 1px;
  position: relative;
  color: #ce1818;
  margin-top: 5px;
}

/* appointment view end */

/* add button style start */

.add-button-grid {
  float: right;
  padding-right: 95px;
}

.add-button-div {
  display: flex;
  justify-content: flex-end;
}

/* add button style end */

/* start appointment btn css */

.finish-btn {
  background-color: #ff0000 !important;
  border: none !important;
  text-transform: none !important;
}

/* start appointment btn css */

/* Poup style */

.your-confirm-button-class {
  background-color: #ff5251;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  padding: 10px 20px;
  width: 120px;
  font-size: 15px;
  margin-right: 12px;
}

.your-cancel-button-class {
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #d6d6d6;
  color: #3d4244;
  padding: 10px 20px;
  margin-right: 10px;
  width: 120px;
  font-size: 15px;
}

.swal2-icon.swal2-warning.deleteicon-class {
  border-color: #ff5251;
  color: #ff5251;
}

.inactive-confirm-button-class {
  background-color: #f17300 !important;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  padding: 10px 20px;
  width: 135px;
  font-size: 15px;
  margin-right: 12px;
}

.inactive-cancel-button-class {
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #d6d6d6;
  color: #3d4244;
  padding: 10px 20px;
  margin-right: 10px;
  width: 135px;
  font-size: 15px;
}

.swal2-icon.swal2-warning.inactive-class {
  border-color: #f17300 !important;
  color: #f17300 !important;
}

.reject-confirm-button-class {
  background-color: #ff5251;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  padding: 10px 20px;
  width: 120px;
  font-size: 15px;
  margin-right: 6px;
}

.reject-confirm-button-class[data-message-val="Reject"] {
  background-color: #ff5251;
}

.reject-confirm-button-class[data-message-val="Approve"] {
  background-color: #41a451;
}

.sub-confirm-button {
  background-color: #15a07d;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  padding: 10px 20px;
  width: 120px;
  font-size: 15px;
}

.sub-cancel-button {
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #15a07d;
  color: #15a07d;
  padding: 10px 20px;
  margin: 0 10px;
  width: 120px;
  font-size: 15px;
  outline: none;
}

.swal2-icon.swal2-warning.sub-icon {
  border-color: #15a07d;
  color: #15a07d;
}

/* icons style */

.swal2-icon.swal2-warning.rejecticon-class {
  border-color: #ff5251;
  color: #ff5251;
}

.swal2-icon.swal2-warning.rejecticon-class[data-message-val="Reject"] {
  border-color: #ff5251;
  color: #ff5251;
}

.swal2-icon.swal2-warning.rejecticon-class[data-message-val="Approve"] {
  color: #41a451;
  border-color: #41a451;
}

.reject-cancel-button-class {
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #d6d6d6;
  color: #3d4244;
  padding: 10px 20px;
  margin-right: 10px;
  width: 120px;
  font-size: 15px;
  margin-left: 6px;
}

.terminate-confirm-button-class {
  background-color: #ac5252;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  padding: 10px 20px;
  width: 135px;
  font-size: 15px;
}

.terminate-cancel-button-class {
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #d6d6d6;
  color: #3d4244;
  padding: 10px 20px;
  margin: 0 10px;
  width: 135px;
  font-size: 15px;
}

.swal2-icon.swal2-warning.terminate-iconclass {
  border-color: #ac5252;
  color: #ac5252;
}

/* Poup style */

/* SP User Managment padding bg color  */

.sp-pending {
  width: 85px;
  padding: 8px 12px;
  background-color: #fdeeba;
  border-radius: 40px;
  color: #e8b500;
  position: relative;
}

.sp-Rejecte {
  width: 85px;
  margin-top: 20px;
  padding: 8px 12px;
  background-color: #ffe8e8;
  border-radius: 40px;
  color: #ff5251;
  position: relative;
}

/* SP User Managment padding bg color  */

/* Off boarding bg color  */

.off-boarding.MuiTypography-root {
  color: #d32f2f;
  position: relative;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-weight: 400;
}

#demo-simple-select:focus {
  outline: none;
}

/* .cal-select-drop {
  width: 120px;
  height: 40px;
  padding: 8.5px 14px 8.5px 14px;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-size: 16px;
} */

.mui-select-wrapper {
  position: relative;
  display: inline-block;
}

.cal-select-drop {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #bdbdbd;
  padding: 8.5px 14px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
}

.mui-select-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.mui-select-arrow svg {
  width: 24px;
  height: 24px;
}

/* Off boarding bg color  */

.leathcare-text.MuiTypography-root {
  text-align: center;
  white-space: nowrap;
  /* margin-top: 20px; */
  /* padding: 8px 12px; */
  padding: 4px 5px;
  background-color: #e2ecf1;
  border-radius: 40px;
  color: #15a07d !important;
  position: relative;
}

.skill-boxbg {
  background: rgba(21, 160, 125, 0.1) !important;
}

.leave-fliter-img {
  margin: -4px;
  width: 16px;
  margin-left: 10px;
}

/* Hc> Interview Schudule css */

.hcinterviewtimer .rc-time-picker-input {
  font-size: 15px;
}

.hcschudule-btn button {
  color: #ffffff;
  background-color: #15a07d;
  width: 136px;
  padding: 14px 0;
  border: 1px solid #15a07d;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
}

.meeting-profile {
  max-width: 250px;
}

.card-image1 {
  max-width: 200px;
  border-radius: 8px;
  /* margin: auto; */
  /* height: 100%; */
  /* width: 100%; */
  object-fit: cover;
}

/* Hc> Interview Schudule css */

/* Master Sidebar menu css */

.addsidebar-btn.MuiButton-root {
  background-color: #15a07d !important;
  text-transform: capitalize;
}

/* Master Sidebar menu css */

/* client list status CSS */

.status-cell2-pending {
  background-color: #a4ffb3;
}

.status-cell2-completed {
  background-color: #ffeba4;
}

.status-cell2-rejected {
  background-color: #ffb0a4;
}

/* Metting Tabs Css Start */

.sh-tab-container {
  border: 1px solid #15a07d;
  border-radius: 8px;
  max-width: 180px;
  height: 1%;
  padding: 2px;
}

.sh-tab-container .sh-tabstyle {
  border-radius: 8px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  min-height: 42px;
  font-size: 17px !important;
  text-transform: capitalize !important;
}

.MuiTabs-fixed .MuiTabs-indicator {
  display: none;
}

.sh-tab-container .sh-tabstyle.Mui-selected {
  background: #15a07d;
  color: #fff;
  border: none !important;
}

.sh-tab-container .sh-tabstyle:hover {
  background-color: #15a07d !important;
  color: #ffffff !important;
}

.sh-tabstyle .tab-text {
  color: #000000;
}

.sh-tabstyle .tab-text:hover {
  background-color: #15a07d !important;
  color: #ffffff !important;
}

.sh-tabs-maincontainer {
  margin-left: 30px;
}

/* Metting Tabs Css Start */

/* Date CSS  */

.datestart.date-input-filed input {
  text-align: start;
  margin-left: 12px;
}

/* Date CSS  */

/* cliet meeting css */

.download-icons {
  color: #15a07d;
}

.single-line.MuiTypography-root {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* cliet meeting css */

/* My Account According  */

.accrdingbg-color {
  background-color: #e8f6f2 !important;
}

.accrdingbg-icon-color {
  color: #15a07d;
}

.accrdingbg-color-clietnview {
  background-color: #e8f6f2 !important;
  width: 100% !important;
}

.client-view-morebtn .MuiTab-wrapper {
  width: 122px;
  border: 1px solid #645dd7;
  display: flex;
  flex-direction: row-reverse;
}

.client-view-more-icon {
  color: #645dd7;
  margin-bottom: 0 !important;
}

/* My Account According  */

/* Master Inputs Filed Css */

.inputposition {
  position: relative !important;
}

/* .inputhealper .css-k4qjio-MuiFormHelperText-root.Mui-error {
  padding-top: 38px;
  position: absolute;
} */

.inputhealper .css-k4qjio-MuiFormHelperText-root.Mui-error {
  margin-top: 38px;
  position: absolute;
}

/* Master Inputs Filed Css */

/* skills  Filed Css */
.hcskill-data {
  background-color: #e2ecf2;
  color: #15a07d !important;
  padding: 5px;
  border-radius: 10px;
  margin-right: 5px;
}

/* skills Filed Css */

.straight-line {
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.meeting-modal-content p {
  /* font-size: 20px; */
  font-size: 14px;
  color: #484747;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-left: 4px;
  /* font-family: "proxima_novasemibold"; */
}

.modal-content-broder {
  border-bottom: 1px solid #d0ece5;
}

.meeting-cancel-content.MuiTypography-root {
  font-size: 18px;
  color: #484747;
  font-family: "proxima_novasemibold";
}

.meeting-cancel-text.MuiTypography-root {
  font-size: 15px;
  /* font-family: "proxima_novasemibold"; */
}

.timesheet-search-box {
  position: relative;
}

.timesheet-search-filter {
  position: absolute;
  bottom: -22px;
  left: -10px;
  min-width: 222px;
  width: 100%;
  height: 32px;
  z-index: 999;
  padding: 0 5px;
  border-radius: 4px;
  border: 1px solid #01c1eb;
  background: #fff;
}

.add-apppointment-btn.MuiButton-root {
  color: #15a07d;
  border-color: #15a07d;
  text-transform: capitalize;
  font-size: 16px;
  border: 1px solid var(--main-dark-color);
  font-family: "proxima_novasemibold";
  padding: 6px 8px;
  border-radius: 5px;
  width: auto;
  line-height: 27px;
  margin-left: 0;
  min-height: 40px;
  white-space: nowrap;
}

.progress-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.completed-value,
.remaining-value {
  color: red;
  font-weight: bold;
}

.remaining-value {
  color: green;
  padding-top: 6px;
}

.date-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 0;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  margin-top: 5px;
}

.completed-progress,
.remaining-progress {
  height: 100%;
  position: absolute;
  top: 0;
}

.completed-progress {
  background-color: red;
}

.remaining-progress {
  background-color: green;
  right: 0;
}

.completed-value,
.remaining-value {
  margin-top: 5px;
}

.routinetask-img {
  object-fit: cover;
  width: 300px;
  height: 200px;
}

.clientdropdown .MuiIconButton-root {
  padding: 2px;
}

.clientview-profile {
  margin-top: 35px !important;
  margin-bottom: -13px !important;
  margin-left: 18px !important;
}

.viewtabs-container {
  background-color: #645dd726;
}

.viewtabs-label {
  text-transform: capitalize !important;
  width: auto !important;
  font-size: 14px !important;
  padding: 0 20px !important;
  min-width: auto !important;
  max-width: 100% !important;
}

.viewtabs-container .MuiTabs-flexContainer {
  justify-content: space-between !important;
}

/* .viewtabs-label.Mui-selected{
  background: #645DD74D;
} */

.mr-contact-position {
  position: relative !important;
}

.emr-contact {
  position: absolute !important;
  /* bottom: -18px;
    left: 0; */
  bottom: -50px;
  left: -41px;
  min-width: 222px;
  width: 100%;
  height: 32px;
  z-index: 999;
  padding: 0 5px;
  border-radius: 4px;
  border: 1px solid #01c1eb;
  background: #fff;
}

/* hc-teams popup stlye */

.hc-onboard-title {
  border-bottom: 1px solid #d0ece5;
  background-color: #d0ece5;
}

.hc-team-btn.MuiButton-root {
  margin-top: 20px;
  text-transform: capitalize;
  color: #645dd7;
  font-size: 16px;
}

.hc-team-removeicon {
  color: red;
}

.underline {
  text-decoration: underline;
}

.progress-circle {
  position: relative;
  width: 60px;
  height: 60px;
}

.goal-svg {
  width: 100%;
  height: 100%;
}

circle {
  fill: transparent;
  stroke-width: 10%;
  /* transform: translate(5%, 5%); */
  transform-origin: center;
}

.goal-table-circle {
  padding: 20px 0 !important;
}

.circle-background {
  stroke: #e0e0e0;
  /* Gray background color */
}

.circle-progress {
  stroke: #00cc00;
  /* Green progress color */
  stroke-dasharray: calc(282.743px * var(--percentage)) 282.743px;
  stroke-dashoffset: 0;
}

.percentage {
  fill: #333;
  font-size: 12px;
  text-anchor: middle;
}

@keyframes progress {
  to {
    stroke-dashoffset: calc(282.743px * (1 - var(--percentage)));
  }
}

.circle-progress {
  stroke: #00cc00;
  /* Green progress color */
  stroke-dasharray: 282.743px;
  stroke-dashoffset: calc(282.743px * (1 - var(--percentage)));
  animation: none;
  /* Remove the animation */
}

.client-bedcrum.MuiTypography-root {
  background-color: #15a07d1a;
  height: 24px;
  width: 25px;
}

.agency-add-new {
  background-color: #e4e4e4;
  color: #fff;
  font-size: 16px;
  border: 1px solid #969696;
  font-family: "proxima_novasemibold";
  display: inline-block;
  padding: 3px 8px;
  border-radius: 5px;
  /* width: 75px; */
  width: auto;
  padding-left: 0px;
  line-height: 27px;
  margin-left: 0;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.agency-add-new svg {
  width: 18px;
}

.agency-add-new:hover {
  background-color: transparent;
  color: #969696;
}

.importexportbtn-new {
  color: #969696 !important;
  border-color: #969696 !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.importexportbtn-new svg {
  color: #969696;
  font-size: 27px !important;
}

.observation .css-ptiqhd-MuiSvgIcon-root {
  color: #15a07d;
  height: 40px;
  width: 30px;
}

.gridItem {
  display: flex;
  align-items: center;
}

.iconImg {
  margin-right: 8px;
}

.title {
  color: #15a07d;
  font-weight: bold;
  margin-bottom: 4px;
}

.link {
  color: black;
  text-decoration: underline;
}

/* 
 =====================
 Tabs Css Start
 */

.blood-tab-container {
  border: 1px solid #15a07d;
  border-radius: 8px;
  max-width: 293px;
  height: 1%;
  padding: 2px;
}

.blood-tab-container .Tabstyle-blood {
  border-radius: 8px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  min-height: 42px;
  font-size: 17px !important;
  text-transform: capitalize !important;
}

.MuiTabs-fixed .MuiTabs-indicator {
  display: none;
}

.blood-tab-container .Tabstyle-blood.Mui-selected {
  background: #15a07d !important;
  color: #fff;
  border: none !important;
}

.blood-tab-container .Tabstyle-blood:hover {
  background-color: #15a07d !important;
  color: #ffffff !important;
}

.Tabstyle-blood .tab-text {
  color: #000000;
}

.Tabstyle-blood .tab-text:hover {
  background-color: #15a07d !important;
  color: #ffffff !important;
}

.Tabstyle-blood:hover img {
  filter: brightness(0) invert(1);
}

.tabs-maincontainer {
  margin-left: 18px;
}

/* 
 =====================
 Tabs Css End
 */

/* Explore Market Card  */

.explore-header-border {
  border-top: 0.5px solid #d0ece5;
  width: 100%;
  margin-bottom: 35px;
  margin-top: 10px;
}

.explore-icon.MuiAvatar-root {
  width: 99px;
  height: 98px;
}

.rating-cion-sty {
  color: #fdd443;
}
.rating-cion-gray {
  color: #82817f;
}

.marketcard-content.MuiTypography-root {
  background: #15a07d33;
  border-radius: 20px;
  color: #15a07d;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
  /* width: 127px; */
  padding: 6px 7px;
  text-align: center;
}

.fav-iconbtn.MuiButton-root {
  border-radius: 4px !important;
  text-transform: capitalize;
  color: #202e2a;
  border-color: #e3e8e6 !important;
}

.res-iconbtn.MuiButton-root {
  border-radius: 4px !important;
  text-transform: capitalize;
  color: #ffffff;
  background-color: #15a07d !important;
}

.res-cancel-iconbtn.MuiButton-root {
  border-radius: 4px !important;
  text-transform: capitalize;
  color: #ffffff;
  background-color: #f02d3a !important;
}

.res-iconbtn-connected.MuiButton-root {
  border-radius: 4px !important;
  text-transform: capitalize;
  color: #ffffff;
  background-color: #5ec8f6 !important;
}

.added-iconbtn.MuiButton-root {
  border-radius: 4px !important;
  text-transform: capitalize;
  color: #15a07d;
  background-color: #15a07d1a !important;
}

.added-iconbtn svg.MuiButton-root {
  color: #15a07d;
}

.explore-view-icon {
  color: #645dd7;
  margin-left: 5px;
}

.more-btn.MuiButton-root {
  text-transform: capitalize;
  color: #645dd7;
  margin-top: -10px;
  padding: 0 !important;
  min-width: 40px;
  background-color: transparent !important;
  background: none !important;
  box-shadow: none !important;
}

.more-btn .MuiTouchRipple-root {
  display: none !important;
}

.acord-summary-sty {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.duc-text.MuiTypography-root {
  font-weight: 600 !important;
}

.duc-text span {
  color: #929495;
}

.cross-iconbtn {
  color: #109648;
  font-weight: bold !important;
}

.accoidng-details-contned {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.medicalcard-sty.MuiTypography-root {
  font-size: 1rem;
  color: #3d4244;
}

/* CSS for export pdf */
.goal-main-container {
  display: flex;
  flex-direction: column;
  /* border: 1px solid gray; */
  margin: 0 20px;
  margin-bottom: 180px;
  padding: 20px;
  border-radius: 2px;
}

.goal-container {
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  margin: 0 20px;
  margin-bottom: 80px;
  padding: 20px;
  border-radius: 2px;
}

.goal-image-container {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  margin-bottom: 130px;
  border-radius: 2px;
}

.goal-child-img-container {
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  margin: 0 20px;
  margin-top: 90px;
  margin-bottom: 100px;
  padding: 20px;
  border-radius: 2px;
}

.goal-info {
  /* display: flex; */
  flex-wrap: wrap;
  /* Allow items to wrap to the next line */
  align-items: center;
  margin-bottom: 10px;
  /* Add margin between goal info and tasks */
}

.goal-info span {
  margin-right: 5px;
  /* Add margin between field names and values */
}

.goal-title {
  font-weight: bold;
  font-size: 18px;
  /* Adjust font size for goal titles */
}

.goal-status {
  color: #007bff;
  /* Add color for goal status */
  /* You can add more styles as needed */
}

.tasks-container {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  /* Remove left margin for tasks container */
}

.task-container {
  display: flex;
  flex-wrap: wrap;
  /* Allow items to wrap to the next line */
  align-items: center;
  border-left: 2px solid #007bff;
  /* Add left border for tasks */
  padding-left: 10px;
  /* Add left padding for tasks */
  margin-top: 10px;
  /* Add margin between tasks */
}

.task-container span {
  margin-right: 10px;
  /* Add margin between field names and values */
}

.task-title {
  font-weight: bold;
  font-size: 16px;
  /* Adjust font size for task titles */
}

.task-status {
  float: right;
}

.icon-text-container {
  display: flex;
  align-items: center;
}

.input-icon {
  margin-right: 5px;
  /* Adjust the margin as needed for spacing between icon and text */
}

.goal-info-black {
  color: black;
}

.goal-info-gray {
  color: gray;
}

.fundVk p {
  font-size: 12px !important;
}

/* .accoidng-details-contned .item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
} */

/* Explore Market Card  */

.gaol-accordion.MuiAccordion-root {
  margin-top: 10px;
}

.user-managment-border {
  border-top: 0.5px solid #d0ece5;

  width: 100%;

  margin-bottom: 10px;
  margin-top: 10px;
}

.delete-task-button {
  color: red;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

.explore-maket-send {
  /* transform: rotate(-27deg) !important; */
  color: rgb(21, 160, 125) !important;
  /* margin-bottom: 25px !important;
  margin-top: -40px !important; */
  /* margin-bottom: 35px !important;
  margin-top: -45px !important; */
}

.explore-maket-send2 {
  /* transform: rotate(-27deg) !important; */
  color: rgb(21, 160, 125) !important;
  margin-top: -19px !important;
  padding: -9px !important;
}

.reqest-document {
  width: 100px;
}

/* margin-top: -19px !important;
    padding: -9px !important; */

.cancel-error-text .css-k4qjio-MuiFormHelperText-root.Mui-error {
  font-size: 0.75rem;
}

/* buil upload error messsage */

.builerror.MuiFormHelperText-root {
  color: #d32f2f !important;
  font-size: 0.75rem !important;
  white-space: nowrap !important;
}

.buil-filter .css-k4qjio-MuiFormHelperText-root {
  margin-left: 0px;
  color: #d32f2f !important;
  font-size: 0.75rem !important;
  white-space: nowrap !important;
}

/* .css-k4qjio-MuiFormHelperText-root */

.explore-tag {
  padding-bottom: 0px !important;
}

.bold-label {
  font-weight: bold;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(39%) sepia(26%) saturate(3786%) hue-rotate(153deg) brightness(94%) contrast(95%);
}

.red-background {
  background-color: red;
  color: white;
  /* Set text color to white for better visibility */
}

.edit-topsection {
  /* padding-top: 6px; */
  /* padding-bottom: 10px; */
  /* width: 100% !important; */
  border-bottom: 0.5px solid #d0ece5;
}

.new-ml label,
.new-ml label>span {
  margin-left: 8px;
}

.web-km {
  padding-top: 0px !important;
}

.web-margin {
  margin-top: 16px;
}

.set-colour {
  color: grey !important;
}

.space-bottom {
  margin-bottom: 25px;
}

.rating-text {
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem !important;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.text-space {
  width: fit-content;
  margin: 0 0 16px 50px;
}

.dash {
  display: block;
  width: 15px;
  margin: 0 auto;
  height: 1px;
  border-bottom: 2px solid rgb(28, 5, 5);
}

.timesheet-custom-cell {
  height: 40px !important;
}

.timesheet-custom-font {
  font-size: 14px;
}

.icon-container {
  position: relative;
}

.mouse-hover-popup {
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
  border: 1px solid black;
}

.icon-container:hover .mouse-hover-popup {
  visibility: visible;
  opacity: 1;
}

.menu-item:hover {
  background-color: #645DD74D !important;
  /* Red color on hover */
}

.menu-item-back {
  background-color: #645dd726 !important;
}

.label-width {
  width: 220px;
}

.fixed-label {
  display: block; 
  font-size: 16px; 
  margin-bottom: 8px; 
  white-space: nowrap;
  width: 100%;
}
.add-button {
  display: flex;
  justify-content: end;
  margin-top: -20px;
  margin-right: 10px;
}
